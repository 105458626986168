import React from "react";
import { Link } from "react-router-dom";
// import image1 from '../../assets/img/main.jpg';
// import image1 from '../../assets/img/gallery/cloudAndDevops-770X541.jpg';
// import image2 from '../../assets/img/gallery/ai1-600x600.avif';
// import image3 from '../../assets/img/gallery/analytics-600X600.jpg';

// import cloudDevops from '../../assets/img/services/cloud-devops.jpg';
// import webApplications from '../../assets/img/services/web-applications.jpg';
// import networkApplications from '../../assets/img/services/network-applications.jpg';
// import designUX from '../../assets/img/services/design-ux.jpg';
// import qualityAssurance from '../../assets/img/services/quality-assurance.jpg';
// import aiAnalytics from '../../assets/img/services/ai-analytics.jpg'

const Details = () => {
  return (
    <section className="service-details-wrapper section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-4 mt-5 mt-md-0 col-12 order-2 order-md-1">
            <div className="service-details-sidebar">
              <div className="single-service-sidebar">
                <div className="sidebar-title">
                  <h3>Core Services</h3>
                </div>
                <ul>
                  <li>
                    <Link to="/services/CloudAndDevops">
                      Cloud &amp; Devops
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/SoftwareDevelopment">
                      Software Development
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/AIAndAnalytics">
                      AI &amp; Analytics
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/DesignUX">UI/UX</Link>
                  </li>
                  <li>
                    <Link to="/services/QualityAssurance">
                      Quality Assurance
                    </Link>
                  </li>
                  <li>
                    <Link to="/services/CyberSecurity">Cyber Security</Link>
                  </li>
                  <li>
                    <Link to="/services/BlockChain">Block Chain</Link>
                  </li>
                  <li>
                    <Link to="/services/EmbeddedSystems">Embedded Systems</Link>
                  </li>
                </ul>
              </div>
              {/* <div className="single-service-sidebar site_info_widget">
                                <div className="sidebar-title">
                                    <h3>Contact Us</h3>
                                </div>
                                <div className="contact-us">
                                    <div className="single-contact-info">
                                        <div className="icon">
                                            <i className="fal fa-phone" />
                                        </div>
                                        <div className="contact-info">
                                            <span>Phone Number</span>
                                            <p>908 9098 987 98</p>
                                        </div>
                                    </div>
                                    <div className="single-contact-info">
                                        <div className="icon">
                                            <i className="fal fa-envelope" />
                                        </div>
                                        <div className="contact-info">
                                            <span>Email Address</span>
                                            <p>info@supportexam.com</p>
                                        </div>
                                    </div>
                                    <div className="single-contact-info">
                                        <div className="icon">
                                            <i className="fal fa-map-marker-alt" />
                                        </div>
                                        <div className="contact-info">
                                            <span>Office Address</span>
                                            <p>14/A, Ping Tower, NYC</p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
            </div>
          </div>
          <div className="col-lg-9 col-md-8 ps-lg-5 col-12 order-1 order-md-2">
            <div className="service-details-content-wrapper pl-0 pl-md-4">
              {/* <img src={image1} alt="" /> */}
              <h2>Cloud &amp; DevOps</h2>
              <p>
                Modernize cloud applications, infrastructure and operations to
                optimize your business processes. Whether it&apos;s migrating to
                the cloud, implementing DevOps practices, or managing your cloud
                infrastructure, we can help you leverage the power of cloud
                computing to achieve your goals.
              </p>
              <h2>Software Development</h2>
              <p>
                Build highly interactive and scalable web applications that meet
                your business needs. Our expert team of developers can help you
                design and develop custom web applications using the latest
                technologies and frameworks to ensure a seamless user
                experience.
              </p>
              <h2>AI &amp; Analytics</h2>
              <p>
                Leverage AI and analytics to boost business performance by
                gaining insights and making data-driven decisions. Our team of
                data scientists and AI experts can help you implement AI and
                analytics solutions that enable you to optimize business
                processes, improve customer experience, and achieve your
                business goals.
              </p>
              <h2>UI/UX</h2>
              <p>
                Design products with a simplified and intuitive user experience
                to enhance customer satisfaction and engagement. Our UX
                designers can help you create user-centered designs that are not
                only visually appealing but also functional and accessible.
              </p>
              <h2>Quality Assurance</h2>
              <p>
                Ensure quality with effective QA services and test automation.
                Our team of experienced QA professionals can help you build a
                comprehensive QA strategy and implement effective test
                automation practices to deliver high-quality software.
              </p>
              <h2>Cyber Security</h2>
              <p>
                Developed a custom cybersecurity framework for a government
                agency, integrating threat intelligence solutions and
                establishing incident response protocols.
              </p>
              <h2>Block Chain</h2>
              <p>
                Implemented a smart contract solution for a renewable energy
                trading platform using Hyperledger Fabric, enhancing the
                efficiency of green energy distribution.
              </p>
              <h2>Embedded Systems & Networking</h2>
              <p>
                Engineered a wearable health monitoring device for a healthcare
                tech company, using ARM Cortex microcontrollers and BLE for data
                transmission.
              </p>
              {/* <div className="row">
                                <div className="col-md-6 col-12">
                                    <img src={image2} alt="" />
                                </div>
                                <div className="col-md-6 col-12">
                                    <img src={image3} alt="" />
                                </div>
                            </div> */}
              <h2>What to Expect?</h2>
              <p>
                At eCav, you can expect cutting-edge software technology
                solutions to solve complex problems. Our team of 200+
                world-class engineers and designers specialize in cloud
                computing, web and network applications, design and UX, quality
                assurance, and AI analytics. We pride ourselves in attracting
                and retaining top talent, and our leadership team has over 75
                years of cumulative global experience in technology development,
                product design, and leadership.
              </p>
              <h2>Our Core Values</h2>
              <ul>
                <li>
                  <strong>Freedom:</strong> We believe in people over process
                  and give our team the freedom to decide &apos;how&apos; to do
                  their work.
                </li>
                <li>
                  <strong>Responsibility:</strong> We encourage self-discipline
                  and responsibility to discover and fix issues without being
                  told to do so.
                </li>
                <li>
                  <strong>Accountability:</strong> We value accountability and
                  believe that responsible freedom leads to reliable, committed
                  team members who take ownership of their work.
                </li>
              </ul>
              <h2>Our Experience and Success</h2>
              <p>
                Founded in 2020, eCa has realized 100% YoY growth and has over
                40 happy customers in new enterprise initiatives and successful
                startup exits. Our unique DNA puts equal emphasis on product
                design, user experience, and solid engineering, and we maximize
                our productivity with agility to ensure that we deliver value
                for every customer dollar spent. At eCav, we take pride in what
                we do, and our customer&apos;s problems are our problems.
                It&apos;s our responsibility to deliver our very best, always.
              </p>
              <blockquote>
                &quot;Our focus is on building the future with our clients, not
                just building software.&quot;
              </blockquote>
            </div>
            <div className="faq-content pl-0 pl-md-4">
              <h2>Common Questions for Our Services:</h2>
              <div className="faq-accordion">
                <div className="accordion no-border me-lg-5" id="accordion">
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq1"
                        aria-expanded="false"
                        aria-controls="faq1"
                      >
                        What is cloud computing and how can it help my business?
                      </button>
                    </h4>
                    <div
                      id="faq1"
                      className="accordion-collapse collapse show"
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        Cloud computing allows businesses to modernize their
                        applications, infrastructure, and operations by
                        leveraging the power of the cloud. Our team can help you
                        migrate to the cloud, implement DevOps practices, and
                        manage your cloud infrastructure to optimize your
                        business processes and achieve your goals.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq2"
                        aria-expanded="true"
                        aria-controls="faq2"
                      >
                        How can your quality assurance services benefit my
                        software development process
                      </button>
                    </h4>
                    <div
                      id="faq2"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        Our team of experienced QA professionals can help you
                        build a comprehensive QA strategy and implement
                        effective test automation practices to deliver
                        high-quality software. By ensuring quality throughout
                        the software development process, we can help you reduce
                        costs, minimize errors, and enhance customer
                        satisfaction.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq3"
                        aria-expanded="false"
                        aria-controls="faq3"
                      >
                        What technologies and frameworks do you use for web
                        application development?
                      </button>
                    </h4>
                    <div
                      id="faq3"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        Our expert team of developers can help you design and
                        develop custom web applications using the latest
                        technologies and frameworks, including React, Angular,
                        Vue.js, Node.js, and more, to ensure a seamless user
                        experience that meets your business needs.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq4"
                        aria-expanded="false"
                        aria-controls="faq4"
                      >
                        How can your network application services help my
                        enterprise network performance?
                      </button>
                    </h4>
                    <div
                      id="faq4"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        Our network engineers can help you design, implement,
                        and manage your network infrastructure to ensure optimal
                        performance and reliability. We can configure and
                        troubleshoot your enterprise networks and provide 24/7
                        network monitoring to identify and resolve issues before
                        they become critical.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq5"
                        aria-expanded="false"
                        aria-controls="faq5"
                      >
                        How can your design and UX services enhance my product?
                      </button>
                    </h4>
                    <div
                      id="faq5"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        Our team of experienced designers can help you create a
                        user-centered design that enhances the functionality and
                        accessibility of your product. By focusing on the user
                        experience, we can help you create a product that not
                        only looks visually appealing but also makes it easy for
                        your customers to use and engage with. This can lead to
                        increased customer satisfaction and engagement, which in
                        turn can improve your business performance.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq6"
                        aria-expanded="false"
                        aria-controls="faq6"
                      >
                        Why is quality assurance important in software
                        development?
                      </button>
                    </h4>
                    <div
                      id="faq6"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        Quality assurance is crucial in software development
                        because it ensures that the software being developed
                        meets the expected level of quality, functionality, and
                        reliability. It involves a comprehensive process of
                        testing and reviewing software to identify and fix any
                        issues or bugs before the software is released to the
                        end-users. By implementing effective quality assurance
                        practices, software development teams can deliver
                        high-quality software that meets the expectations of
                        their users, resulting in increased user satisfaction,
                        improved brand reputation, and reduced maintenance
                        costs.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq7"
                        aria-expanded="false"
                        aria-controls="faq7"
                      >
                        How can AI and analytics benefit my business?
                      </button>
                    </h4>
                    <div
                      id="faq7"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        AI and analytics can provide a range of benefits for
                        your business, including gaining insights into customer
                        behavior and preferences, optimizing business processes,
                        and improving decision-making. By leveraging AI and
                        analytics, you can automate routine tasks, identify
                        trends and patterns in data, and make data-driven
                        decisions that can improve your bottom line. Our team of
                        data scientists and AI experts can work with you to
                        develop custom solutions that meet your specific
                        business needs.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Details;
