import { v4 as uuidv4 } from 'uuid';

const tagsData = [
    {
        id: uuidv4(),
        link: '#',
        label: 'event'
    },

    {
        id: uuidv4(),
        link: '#',
        label: 'help'
    },

    {
        id: uuidv4(),
        link: '#',
        label: 'ux'
    },

    {
        id: uuidv4(),
        link: '#',
        label: 'food'
    },

    {
        id: uuidv4(),
        link: '#',
        label: 'ui'
    },

    {
        id: uuidv4(),
        link: '#',
        label: 'water'
    },

    {
        id: uuidv4(),
        link: '#',
        label: 'charity'
    },

    {
        id: uuidv4(),
        link: '#',
        label: 'donate'
    }
]

export default tagsData;
