import imgMoniLog1 from "../../assets/img/services/monitAndLoggingServ/influx-db-144X144.png";
import imgMoniLog2 from "../../assets/img/services/monitAndLoggingServ/elk-stack-144X144.png";
import imgMoniLog3 from "../../assets/img/services/monitAndLoggingServ/grafana-144X144.png";
import imgMoniLog4 from "../../assets/img/services/monitAndLoggingServ/azure-monitor-144X144.png";
import imgMoniLog5 from "../../assets/img/services/monitAndLoggingServ/gray-log-144X144.png";
import imgMoniLog6 from "../../assets/img/services/monitAndLoggingServ/aws-config-144X144.png";
import imgMoniLog7 from "../../assets/img/services/monitAndLoggingServ/prometheus-144X144.png";

import imgDataPipeLinesLog1 from "../../assets/img/services/dataPipelines/aws-data-pipeline-144X144.png";
import imgDataPipeLinesLog2 from "../../assets/img/services/dataPipelines/azure-data-factory-144X144.png";
import imgDataPipeLinesLog3 from "../../assets/img/services/dataPipelines/azure-data-lake-144X144.png";
import imgDataPipeLinesLog4 from "../../assets/img/services/dataPipelines/azure-streaming-144X144.png";
import imgDataPipeLinesLog5 from "../../assets/img/services/dataPipelines/k2-logo-144X144.png";
import imgDataPipeLinesLog6 from "../../assets/img/services/dataPipelines/kinesis-144X144.png";
import imgDataPipeLinesLog7 from "../../assets/img/services/dataPipelines/lake-formation-144X144.png";
import imgDataPipeLinesLog8 from "../../assets/img/services/dataPipelines/redshift-144X144.png";

import imgCiCdLog1 from "../../assets/img/services/ciCd/argo-144X144.png";
import imgCiCdLog2 from "../../assets/img/services/ciCd/arm-templates-144X144.png";
import imgCiCdLog3 from "../../assets/img/services/ciCd/aws-code-commit-144X144.png";
import imgCiCdLog4 from "../../assets/img/services/ciCd/azure-automation-runbooks-144X144.png";
import imgCiCdLog5 from "../../assets/img/services/ciCd/azure-boards-144X144.png";
import imgCiCdLog6 from "../../assets/img/services/ciCd/azure-devops-144X144.png";
import imgCiCdLog7 from "../../assets/img/services/ciCd/bitrise-144X144.png";
import imgCiCdLog8 from "../../assets/img/services/ciCd/code-build-144X144.png";
import imgCiCdLog9 from "../../assets/img/services/ciCd/code-deploy-144X144.png";
import imgCiCdLog10 from "../../assets/img/services/ciCd/control-tower-144X144.png";
import imgCiCdLog11 from "../../assets/img/services/ciCd/github-144X144.png";
import imgCiCdLog12 from "../../assets/img/services/ciCd/gitlab-144X144.png";
import imgCiCdLog13 from "../../assets/img/services/ciCd/jenkins-144X144.png";
import imgCiCdLog14 from "../../assets/img/services/ciCd/system-manager-144X144.png";

import imgSecurity1 from "../../assets/img/services/security/key-vault-144X144.png";

import imgCostOpt1 from "../../assets/img/services/costOptimize/aws-budgets-144X144.png";
import imgCostOpt2 from "../../assets/img/services/costOptimize/aws-cost-explorer-144X144.png";
import imgCostOpt3 from "../../assets/img/services/costOptimize/cloud-watch-144X144.png";
import imgCostOpt4 from "../../assets/img/services/costOptimize/trusted-advisor-144X144.png";

import imgAmazonApiGateway from "../../assets/img/services/cloudAndDevops/amazon-api-gateway-144X144.png";
import imgAmazonEcs from "../../assets/img/services/cloudAndDevops/amazon-ecs-144X144.png";
import imgAmbassador from "../../assets/img/services/cloudAndDevops/ambassador-144X144.png";
import imgAzureContainerInstance from "../../assets/img/services/cloudAndDevops/azure-container-instance-144X144.png";
import imgDocker from "../../assets/img/services/cloudAndDevops/docker-144X144.png";
import imgAmazonApiMgmt from "../../assets/img/services/cloudAndDevops/amazon-api-mgmt-144X144.png";
import imgAmazonEks from "../../assets/img/services/cloudAndDevops/amazon-eks-144X144.png";
import imgAnsible from "../../assets/img/services/cloudAndDevops/ansible-144X144.png";
import imgAzureKubernetes from "../../assets/img/services/cloudAndDevops/azure-kurnetes-144X144.png";
import imgKubernetes from "../../assets/img/services/cloudAndDevops/kubernetes-144X144.png";

const imagesMoniLog = [
    { src: imgMoniLog1, alt: "influx" },
    { src: imgMoniLog2, alt: "elk-stack" },
    { src: imgMoniLog3, alt: "grafana" },
    { src: imgMoniLog4, alt: "azure-monitor" },
    { src: imgMoniLog5, alt: "gray-log" },
    { src: imgMoniLog6, alt: "aws-config" },
    { src: imgMoniLog7, alt: "prometheus" }
];

const imgDataPipelines = [
    { src: imgDataPipeLinesLog1, alt: "aws-pipe" },
    { src: imgDataPipeLinesLog2, alt: "azure-facotry" },
    { src: imgDataPipeLinesLog3, alt: "azure-lake" },
    { src: imgDataPipeLinesLog4, alt: "azure-streaming" },
    { src: imgDataPipeLinesLog5, alt: "k2-logo" },
    { src: imgDataPipeLinesLog6, alt: "kinesis" },
    { src: imgDataPipeLinesLog7, alt: "lake-Formation" },
    { src: imgDataPipeLinesLog8, alt: "redShift" }
];

const imgCiCd = [
    { src: imgCiCdLog1, alt: "argo" },
    { src: imgCiCdLog2, alt: "arm-tem" },
    { src: imgCiCdLog3, alt: "aws-code" },
    { src: imgCiCdLog4, alt: "azure-auto" },
    { src: imgCiCdLog5, alt: "azure-board" },
    { src: imgCiCdLog6, alt: "azure-devop" },
    { src: imgCiCdLog7, alt: "bitrise" },
    { src: imgCiCdLog8, alt: "code-build" },
    { src: imgCiCdLog9, alt: "code-deploy" },
    { src: imgCiCdLog10, alt: "control-tower" },
    { src: imgCiCdLog11, alt: "github" },
    { src: imgCiCdLog12, alt: "gitlab" },
    { src: imgCiCdLog13, alt: "jenkins" },
    { src: imgCiCdLog14, alt: "sys-man" }
];

const imgSecurity = [{ src: imgSecurity1, alt: "key-vault" }];

const imgCostOpt = [
    { src: imgCostOpt1, alt: "aws-budgets" },
    { src: imgCostOpt2, alt: "aws-cost" },
    { src: imgCostOpt3, alt: "cloud-watch" },
    { src: imgCostOpt4, alt: "trusted-advisor" }
];

const imgCloudAndDev = [
    { src: imgAmazonApiGateway, alt: "amazon-api-gateway" },
    { src: imgAmazonEcs, alt: "amazon-ecs" },
    { src: imgAmbassador, alt: "ambassador" },
    { src: imgAzureContainerInstance, alt: "azure-container-instance" },
    { src: imgDocker, alt: "docker" },
    { src: imgAmazonApiMgmt, alt: "amazon-api-mgmt" },
    { src: imgAmazonEks, alt: "amazon-eks" },
    { src: imgAnsible, alt: "ansible" },
    { src: imgAzureKubernetes, alt: "azure-kurnetes" },
    { src: imgKubernetes, alt: "kubernetes" }
];

export {
    imagesMoniLog,
    imgCloudAndDev,
    imgCiCd,
    imgSecurity,
    imgCostOpt,
    imgDataPipelines
};