import { v4 as uuidv4 } from 'uuid';
import img1 from '../../assets/img/home3/quotes.png';

const TestimonialTwoData = [

     {
        id: uuidv4(),
        img: img1,
        name: "Yuntao Jia",
        designation: "CTO and Cofounder, OneMarket",
        speech: "Very capable and autonomous team that can build products from the ground up, great team culture and dynamics, love working with those folks!"
    },
    {
        id: uuidv4(),
        img: img1,
        name: "Max Hsieh",
        designation: "CTO Forma",
        speech: "We’ve been working with eCav for about 3 years now, and the team is always responsive, knowledgeable and dependable. They’ve been a great partner to help boost our development firepower!"
    },
    {
        id: uuidv4(),
        img: img1,
        name: "Aashish Singhvi",
        designation: "CEO DVSum",
        speech: "This project was high complexity and the eCav team demonstrated excellent qualities throughout. No corners were cut and a diligent and thorough QA was done to ensure (that) the produced solution was of high quality. Two thumbs-up and highly recommended."
    },
    {
        id: uuidv4(),
        img: img1,
        name: "Ritesh Kumar",
        designation: "Sr. Director of Information Technology, Intrado",
        speech: "eCav team exceeded our expectations in all the aspect of product Development. We partnered with them since when our startup was just on initial phase to building the idea. eCav designed the mockups, Software Development, contributed to product management process and owned all aspects of software development. We feel providential to have found them as a technology partner!"
    },
    {
        id: uuidv4(),
        img: img1,
        name: "Markus Michel",
        designation: "CEO & Founder, CapitalBee",
        speech: "eCav are keen to embrace new challenges and work tirelessly to accomplish the common goal. I admire the team's enthusiasm and sense of pride in their work."
    },
    {
        id: uuidv4(),
        img: img1,
        name: "Abbas Valliani",
        designation: "CEO, Safe Kids AI",
        speech: "It has been an absolute pleasure working with eCav in bootstrapping Safe Kids. I was impressed with their expertise in AWS and Cloud Native development, and would certainly recommend them for their services."
    },
    {
        id: uuidv4(),
        img: img1,
        name: "Grant Morgan",
        designation: "Head of Engineering, WeGift",
        speech: "eMumba has been a key partner to WeGift for 3 years, providing engineering team augmentation. Their engineers are some of the most dedicated and highly skilled, establishing themselves as subject matter experts in their respective domains; they proactively coach new team members in both product and technical capability. The team is highly professional and a pleasure to work with. I highly recommend them!"
    }

]

export default TestimonialTwoData;