import React from "react";
import FooterTwo from "../Components/Footer/FooterTwo";
import HeaderThree from "../Components/Header/HeaderThree";
import bannerBg from "../assets/img/gov.jpg";
import PageBanner from "../Components/PageBanner";
import CapabilityStatement from "../Components/Goverment/CapabilityStatement";
// import BrandCarouselThree from "../Components/Brands/BrandCarouselThree";
// import ContactPageContents from "../Components/Contact/ContactPageContents";

// import { Element } from "react-scroll";

const GovermentPage = () => {
  return (
    <>
      <HeaderThree />
      <PageBanner title="Goverment" bannerBg={bannerBg} currentPage="goverment" />
      {/* <Element name="contact">
        <ContactPageContents />
      </Element> */}
      {/* <BrandCarouselThree /> */}
      <CapabilityStatement />
      <FooterTwo />
    </>
  );
};

export default GovermentPage;
