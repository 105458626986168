import { v4 as uuidv4 } from "uuid";
import portfolio1 from "../../assets/img/home3/portfolio/torus-investments.png";
import portfolio2 from "../../assets/img/home3/portfolio/safe-kids-ai.png";
import portfolio3 from "../../assets/img/home3/portfolio/networks-company.png";
import portfolio4 from "../../assets/img/home3/portfolio/cognitive-technology.png";
import portfolio5 from "../../assets/img/home3/portfolio/bts-stg.png";
import portfolio6 from "../../assets/img/home3/portfolio/dvsum-stg.png";
import portfolio7 from "../../assets/img/home3/portfolio/fetch-ai.png";
import portfolio8 from "../../assets/img/home3/portfolio/lexstep-stg.png";
import portfolio9 from "../../assets/img/home3/portfolio/hardware-company.png";
import portfolio10 from "../../assets/img/home3/portfolio/live-action-stg.png";
import portfolio11 from "../../assets/img/home3/portfolio/solution-integrators.png";

const PortfolioThreeData = [
    {
        id: uuidv4(),
        img: portfolio1,
        title: 'eCav Helps Torus Investments Ensure Secure Communications to AWS-Hosted Crypto Exchanges with Low Latency',
        category: 'Project',
        index: '01'
    },
    {
        id: uuidv4(),
        img: portfolio2,
        title: "Safe Kids Leverages the Power to Automate Infrastructure Deployments on AWS with eCav's Help",
        category: 'Project',
        index: '02'
    },
    {
        id: uuidv4(),
        img: portfolio3,
        title: 'A networks company - Security, analytics and automation',
        category: 'Project',
        index: '03'
    },
    {
        id: uuidv4(),
        img: portfolio4,
        title: 'The cognitive technology for the self-driving enterprise',
        category: 'Project',
        index: '04'
    },
    {
        id: uuidv4(),
        img: portfolio5,
        title: 'BTS - Solution for professional traders',
        category: 'Project',
        index: '05'
    },
    {
        id: uuidv4(),
        img: portfolio6,
        title: 'DVSUM - System to auto-discover and fix issues in data',
        category: 'Project',
        index: '06'
    },
    {
        id: uuidv4(),
        img: portfolio7,
        title: 'Fetch.ai - A decentralized world for the future economy',
        category: 'Project',
        index: '07'
    },
    {
        id: uuidv4(),
        img: portfolio8,
        title: 'Lexstep - The hiring marketplace for top-tier solicitors',
        category: 'Project',
        index: '08'
    },
    {
        id: uuidv4(),
        img: portfolio9,
        title: 'A hardware company - A silicon photonic quantum computer',
        category: 'Project',
        index: '09'
    },
    {
        id: uuidv4(),
        img: portfolio10,
        title: 'Live Action - The leader in network performance management',
        category: 'Project',
        index: '10'
    },
    {
        id: uuidv4(),
        img: portfolio11,
        title: 'Solution integrators - Software solutions integrator in KSA.',
        category: 'Project',
        index: '11'
    }
];

export default PortfolioThreeData;