import React from 'react'
import FooterTwo from '../Components/Footer/FooterTwo'
import HeaderThree from '../Components/Header/HeaderThree'
import bannerBg from '../assets/img/Embedded-systems-banner.jpg';
import PageBanner from '../Components/PageBanner';
import EmbeddedSystemDetail from '../Components/Services/EmbeddedSystemDetail';

const ServiceDetails = () => {
  return (
    <>
        <HeaderThree/>
        <PageBanner title='Embedded Systems & Networking' bannerBg={bannerBg} currentPage='/services/EmbeddedSystems' bgColor='#f5f5f5' />
        <EmbeddedSystemDetail/>
        <FooterTwo/>
    </>
  )
}

export default ServiceDetails;