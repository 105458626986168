import { v4 as uuidv4 } from 'uuid';
import brand1 from '../../assets/img/webserv-toolset/react-160.png';
import brand2 from '../../assets/img/webserv-toolset/nodejs-144.png';
import brand3 from '../../assets/img/webserv-toolset/angularjs-144.png';
import brand4 from '../../assets/img/webserv-toolset/html-5-144.png';
import brand5 from '../../assets/img/webserv-toolset/bootstrap-5-144.png';
import brand6 from '../../assets/img/webserv-toolset/jquery-3-144.png';
import brand7 from '../../assets/img/webserv-toolset/mongodb-icon-1-144.png';
import brand8 from '../../assets/img/webserv-toolset/java-4-144.png';
import brand9 from '../../assets/img/webserv-toolset/backbone-icon-144.png';
import brand10 from '../../assets/img/webserv-toolset/mysql-6-144.png';
import brand11 from '../../assets/img/webserv-toolset/next-js-144.png';
import brand12 from '../../assets/img/webserv-toolset/vue-js-1-144.png';
import brand13 from '../../assets/img/webserv-toolset/typescript-144.png';
// import brand11 from '../../assets/img/brand-logo/11-ecav.png';

const brandCarouselOneData = [
    {
        id: uuidv4(),
        brand: brand1,
        name: 'React'
    },

    {
        id: uuidv4(),
        brand: brand2,
        name: 'Node JS'
    },

    {
        id: uuidv4(),
        brand: brand3,
        name: 'Angular Js'
    },

    {
        id: uuidv4(),
        brand: brand4,
        name: 'Html 5'
    },

    {
        id: uuidv4(),
        brand: brand5,
        name: 'Bootstrap 5'
    },

    {
        id: uuidv4(),
        brand: brand6,
        name: 'Jquery'
    },
    {
        id: uuidv4(),
        brand: brand7,
        name: 'Mongo Db'
    },
    {
        id: uuidv4(),
        brand: brand8,
        name: 'Java'
    },
    {
        id: uuidv4(),
        brand: brand9,
        name: 'Backbone Js'
    },
    {
        id: uuidv4(),
        brand: brand10,
        name: 'MySql'
    },
    {
        id: uuidv4(),
        brand: brand11,
        name: 'Next Js'
    },
    {
        id: uuidv4(),
        brand: brand12,
        name: 'Vue Js'
    },
    {
        id: uuidv4(),
        brand: brand13,
        name: 'Typescript'
    }
]

export default brandCarouselOneData;