import { Swiper, SwiperSlide } from "swiper/react";
import slide1 from "../../assets/img/home1/1-big-data.png";
import slide2 from "../../assets/img/home1/1-cloud.png";
import slide3 from "../../assets/img/home1/1-ai.png";

import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from 'swiper/modules';
import "swiper/css/autoplay";

const HeroOne = () => {
    return (
        <section className="hero-wrapper hero-1">
            <Swiper
                navigation={true}
                modules={[Navigation]}
                slidesPerView={1}
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 10000,
                    disableOnInteraction: false
                }}
                loop={true}
                loopedSlides={5}
            >
                <SwiperSlide
                    className=" bg-cover"
                    style={{ backgroundImage: `url(${slide1})` }}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-12 ps-md-5 pe-md-5 col-xxl-7 col-lg-8 col-md-8 col-sm-10">
                                <div className="hero-contents pe-lg-3">
                                    <h1
                                        className="fs-lg wow fadeInLeft animated"
                                        data-wow-duration="1.3s"
                                    >
                                        Empower Business via Data-Driven
                                        Insights
                                    </h1>
                                    <p
                                        className="pe-lg-5 wow fadeInLeft animated"
                                        data-wow-duration="1.3s"
                                        data-wow-delay=".4s"
                                    >
                                        At eCav, we specialize in creating
                                        cutting-edge data solutions for
                                        businesses of all sizes. Our team of
                                        experienced professionals excels in data
                                        science, data pipelines, and data
                                        engineering, helping you turn your data
                                        into insights that drive business
                                        success. Whether you need to build a
                                        custom data analytics platform,
                                        implement machine learning algorithms,
                                        or optimize your data infrastructure, we
                                        have the expertise to help you achieve
                                        your goals. With our data-driven
                                        approach and advanced analytics tools,
                                        you can gain a deeper understanding of
                                        your business and make better decisions
                                        that drive growth and profitability.
                                    </p>
                                    <a
                                        href="contact.html"
                                        className="theme-btn me-sm-4 wow fadeInLeft"
                                        data-wow-duration="1.2s"
                                        data-wow-delay=".8s"
                                    >
                                        contact us
                                    </a>
                                    <a
                                        href="about.html"
                                        className="plus-text-btn wow fadeInLeft"
                                        data-wow-duration="1.2s"
                                        data-wow-delay=".6s"
                                    >
                                        <div className="icon">
                                            <i className="fas fa-plus"></i>
                                        </div>
                                        <div className="link-text">
                                            <span>Explore</span> More about Us
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide
                    className=" bg-cover"
                    style={{ backgroundImage: `url(${slide2})` }}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-12 ps-md-5 pe-md-5 col-xxl-7 col-lg-8 col-md-8 col-sm-10">
                                <div className="hero-contents pe-lg-3">
                                    <h1
                                        className="fs-lg wow fadeInLeft animated"
                                        data-wow-duration="1.3s"
                                    >
                                        Optimize Operations: Cloud Computing,
                                        DevOps
                                    </h1>
                                    <p
                                        className="pe-lg-5 wow fadeInLeft animated"
                                        data-wow-duration="1.3s"
                                        data-wow-delay=".4s"
                                    >
                                        At eCav, we provide comprehensive cloud
                                        computing and DevOps solutions that help
                                        businesses of all sizes operate more
                                        efficiently and effectively. Our team of
                                        experienced professionals specializes in
                                        building and managing cloud-based
                                        infrastructure, implementing agile
                                        software development processes, and
                                        optimizing IT operations to maximize
                                        productivity and minimize downtime.
                                        Whether you need to migrate to the
                                        cloud, implement a DevOps strategy, or
                                        optimize your existing infrastructure,
                                        we have the expertise to help you
                                        transform your business for the digital
                                        age.
                                    </p>
                                    <a
                                        href="contact.html"
                                        className="theme-btn me-sm-4 wow fadeInLeft"
                                        data-wow-duration="1.2s"
                                        data-wow-delay=".8s"
                                    >
                                        contact us
                                    </a>
                                    <a
                                        href="about.html"
                                        className="plus-text-btn wow fadeInLeft"
                                        data-wow-duration="1.2s"
                                        data-wow-delay=".6s"
                                    >
                                        <div className="icon">
                                            <i className="fas fa-plus"></i>
                                        </div>
                                        <div className="link-text">
                                            <span>Explore</span> More about Us
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide
                    className=" bg-cover"
                    style={{ backgroundImage: `url(${slide3})` }}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-12 ps-md-5 pe-md-5 col-xxl-7 col-lg-8 col-md-8 col-sm-10">
                                <div className="hero-contents pe-lg-3">
                                    <h1
                                        className="fs-lg wow fadeInLeft animated"
                                        data-wow-duration="1.3s"
                                    >
                                        AI Analytics: Boost Business Performance
                                    </h1>
                                    <p
                                        className="pe-lg-5 wow fadeInLeft animated"
                                        data-wow-duration="1.3s"
                                        data-wow-delay=".4s"
                                    >
                                        At eCav, we specialize in developing AI
                                        analytics and predictive modeling
                                        solutions that help businesses gain
                                        insights, make better decisions, and
                                        improve operational efficiency. Our team
                                        of experienced professionals excels in
                                        building custom machine learning models,
                                        developing data-driven algorithms, and
                                        implementing predictive analytics to
                                        help you gain a competitive edge.
                                        Whether you need to predict customer
                                        behavior, optimize your supply chain, or
                                        improve your decision-making processes,
                                        we have the expertise to help you
                                        harness the power of AI and data-driven
                                        insights.
                                    </p>
                                    <a
                                        href="contact.html"
                                        className="theme-btn me-sm-4 wow fadeInLeft"
                                        data-wow-duration="1.2s"
                                        data-wow-delay=".8s"
                                    >
                                        contact us
                                    </a>
                                    <a
                                        href="about.html"
                                        className="plus-text-btn wow fadeInLeft"
                                        data-wow-duration="1.2s"
                                        data-wow-delay=".6s"
                                    >
                                        <div className="icon">
                                            <i className="fas fa-plus"></i>
                                        </div>
                                        <div className="link-text">
                                            <span>Explore</span> More about Us
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </section>
    );
};

export default HeroOne;