import { v4 as uuidv4 } from 'uuid';

const workProcessData = [
    {
        id: uuidv4(),
        icon: 'writing',
        subTitle: 'Sketch',
        title: 'Core Planning',
        desc: 'Our promise as a contractor is to build community value into every projects while delivering professional'
    },

    {
        id: uuidv4(),
        icon: 'flow-chart',
        subTitle: 'Project',
        title: 'Execution Step',
        desc: 'Our promise as a contractor is to build community value into every projects while delivering professional'
    },

    {
        id: uuidv4(),
        icon: 'interior-design',
        subTitle: 'Architect',
        title: 'Project Result',
        desc: 'Our promise as a contractor is to build community value into every projects while delivering professional'
    }
]

export default workProcessData;