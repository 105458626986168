import FooterTwo from "../Components/Footer/FooterTwo";
import HeaderThree from "../Components/Header/HeaderThree";
// import HeroOne from "../Components/Hero/HeroOne";
import HeroSocials from "../Components/Hero/HeroSocials";
import ServicesThree from "../Components/Services/ServicesThree";
import BrandCarouselFour from "../Components/Brands/BrandCarouselFour";
// import AboutThree from "../Components/About/AboutThree";
// import CtaTwo from "../Components/Cta/CtaTwo";
// import TeamFour from "../Components/Team/TeamFour";
import CoreFeaturesOne from "../Components/Features/CoreFeaturesOne";
// import PortfolioThree from "../Components/Portfolio/PortfolioThree";
// import TestimonialOne from "../Components/Testimonial/TestimonialOne";
// import BlogThree from "../Components/Blog/BlogThree";
// import FooterForm from "../Components/Contact/FooterForm";
import HeroFive from "../Components/Hero/HeroFive";
// import CoreFeatures from "../Components/Services/CoreFeatures";
// import HeroAbout from "../Components/Hero/HeroAbout";

const HomeThree = () => {
  return (
    <>
      <HeaderThree />
      <HeroFive />
      {/* <HeroAbout /> */}
      <HeroSocials />
      {/* <HeroOne /> */}
      <ServicesThree />
      {/* <CoreFeatures /> */}
      {/* <AboutThree /> */}
      {/* <CtaTwo /> */}
      <CoreFeaturesOne />
      {/* <PortfolioThree /> */}
      <BrandCarouselFour />
      {/* <TestimonialOne /> */}
      {/* <FooterForm /> */}
      <FooterTwo />
    </>
  );
};

export default HomeThree;
