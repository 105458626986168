import { v4 as uuidv4 } from "uuid";

import bg1 from "../../assets/img/embed-3.jpg";
import bg2 from "../../assets/img/networkAppBg/network-config-compressed.jpg";
import bg3 from "../../assets/img/embedded-system.jpg";

const servicesOneData = [
  {
    id: uuidv4(),
    bg: bg1,
    icon: "cloud-server2",
    title: "Wearable Health Monitoring Device",
    desc:
      "Engineered a wearable health monitoring device for a healthcare tech company, using ARM Cortex microcontrollers and BLE for data transmission."
  },
  {
    id: uuidv4(),
    bg: bg2,
    icon: "network-deploy",
    title: "Digital Banking Platform Development",
    desc:
      "Led the digital transformation of a banking institution, developing a digital banking platform with Spring Boot, Angular, and a microservices architecture."
  },
  {
    id: uuidv4(),
    bg: bg3,
    icon: "netwrok-security",
    title: "Smart City Infrastructure Development",
    desc:
      "Developed a smart city infrastructure for a municipal government, involving the deployment of IoT sensors, a big data management platform, and an AI-driven analytics engine."
  }
];

export default servicesOneData;
