import { v4 as uuidv4 } from 'uuid';
// import img1 from '../../assets/img/home2/s1.jpg';
// import img2 from '../../assets/img/home2/s2.jpg';
// import img3 from '../../assets/img/home2/s3.jpg';

import img1 from '../../assets/img/partners/aws-2-374X230.png';
import img2 from '../../assets/img/partners/imply-data-seeklogo.com-374X230.png';
import img3 from '../../assets/img/partners/intel-374X230.png';
import img4 from '../../assets/img/partners/microsoft-374X230.png';

const servicesTwoData = [
    {
        id: uuidv4(),
        img: img1,
        title: 'Service Delivery',
        desc: 'Amazon RDS'
    },

    {
        id: uuidv4(),
        img: img2,
        title: 'Consulting & System',
        desc: 'Integration Partner'
    },

    {
        id: uuidv4(),
        img: img3,
        title: 'Network Builders',
        desc: 'Solution Partner'
    },

    {
        id: uuidv4(),
        img: img4,
        title: 'Data Analytics',
        desc: 'Gold Partner'
    }

]

export default servicesTwoData;