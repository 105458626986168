import React from "react";
// import { Link } from "react-router-dom";
// import image1 from "../../assets/img/gallery/hybrid-cloud-concept.png";
// import image2 from "../../assets/img/gallery/data-pipe-cloud.png";
// import image3 from "../../assets/img/gallery/cost-opt.png";

import CloudAndDevOpsCarousel from "../../Components/Brands/CloudAndDevOpsCarousel";

import { imagesMoniLog, imgCiCd, imgSecurity, imgCostOpt, imgDataPipelines } from "./cloudAndDevopsImagesImports";

import { Link, Element } from "react-scroll";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";

const Details = () => {
  return (
    <section className="service-details-wrapper section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-4 mt-5 mt-md-0 col-12 order-2 order-md-1">
            <div className="service-details-sidebar sticky-sidebar">
              <div className="single-service-sidebar">
                <div className="sidebar-title">
                  <h3>Cloud and Devops</h3>
                </div>
                <ul>
                  <li>
                    <Link activeClass="active" to="cloud-native-architecture" spy={true} smooth={true} offset={-70} duration={500}>
                      Cloud Native Architecture
                    </Link>
                  </li>
                  <li>
                    <Link activeClass="active" to="data-pipeline" spy={true} smooth={true} offset={-70} duration={500}>
                      Data Pipelines, Big Data and Analytics Services
                    </Link>
                  </li>
                  <li>
                    <Link activeClass="active" to="ci-cd" spy={true} smooth={true} offset={-70} duration={500}>
                      Continuous Integration Continuous Deployment Service
                    </Link>
                  </li>
                  <li>
                    <Link activeClass="active" to="monitoring-and-logging" spy={true} smooth={true} offset={-70} duration={500}>
                      Monitoring and Logging Service
                    </Link>
                  </li>
                  <li>
                    <Link activeClass="active" to="cost-optimization" spy={true} smooth={true} offset={-70} duration={500}>
                      Cost Optimization
                    </Link>
                  </li>
                  <li>
                    <Link activeClass="active" to="security" spy={true} smooth={true} offset={-70} duration={500}>
                      Security
                    </Link>
                  </li>
                </ul>
              </div>
              {/* <div className="single-service-sidebar site_info_widget">
                <div className="sidebar-title">
                  <h3>Contact Us</h3>
                </div>
                <div className="contact-us">
                  <div className="single-contact-info">
                    <div className="icon">
                      <i className="fal fa-phone" />
                    </div>
                    <div className="contact-info">
                      <span>Phone Number</span>
                      <p>+1 202 714 4236 </p>
                    </div>
                  </div>
                  <div className="single-contact-info">
                    <div className="icon">
                      <i className="fal fa-envelope" />
                    </div>
                    <div className="contact-info">
                      <span>Email Address</span>
                      <p>info@ecavtechnologies.com</p>
                    </div>
                  </div>
                  <div className="single-contact-info">
                    <div className="icon">
                      <i className="fal fa-map-marker-alt" />
                    </div>
                    <div className="contact-info">
                      <span>Office Address</span>
                      <p>
                        23266 Southdown Loudoun Valley
                        <br /> Ashburn VA 20148, USA
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="col-lg-9 col-md-8 ps-lg-5 col-12 order-1 order-md-2">
            <div className="service-details-content-wrapper pl-0 pl-md-4">
              <Element name="cloud-native-architecture" style={{ marginBottom: "0px" }}>
                <h2>Cloud and DevOps by eCav</h2>
                {/* <img src={image1} alt="" /> */}
                <p>
                  Cloud Native Architecture Our cloud certified architects can work with your team to either build a cloud native architecture from scratch, or recast your existing application architecture to achieve the target requirements. We can quickly identify areas of improvement in your architecture and propose cloud native elements including pub-sub, streaming, microservices and
                  server-less components.
                </p>
                {/* <p>
                  Cloud Native Architecture employs the fundamental principle of on-demand provisioning in cloud computing to create reliable, resilient, and cost-effective solutions with a quicker time to market compared to traditional architectures. By decoupling architectural components, this approach enables continuous enhancement and deployment of your product without any downtime. Our
                  cloud-certified architects at eCav can collaborate with your team to either develop a cloud-native architecture from the ground up or redesign your current application architecture to meet desired objectives. We can rapidly pinpoint areas for improvement in your existing architecture and suggest cloud-native components such as pub-sub, streaming, microservices, and serverless
                  technologies.
                </p> */}
                {/* <CloudAndDevOpsCarousel carouselData={imgCloudAndDev} /> */}
              </Element>
              <Element name="data-pipeline" style={{ marginBottom: "50px" }}>
                <h2>Data Pipelines, Big Data And Analytics Services by eCav</h2>
                {/* <img src={image2} alt="" /> */}
                <p>
                  In our digitized world, both data and data sources are growing exponentially. We know that data is gold; however, driving business value is not easy for most companies. This challenge arises due to the canonical variability, velocity, and volume of Big Data, or the inability to provision and maintain the infrastructure required to ingest, manipulate, and visualize it. Our
                  engineering team has the technical expertise to setup infrastructure that can handle petabytes of data from multiple sources on a daily basis. The team is also adept in producing lusciously beautiful dashboards that bring actionable insights for the decisions makers.
                </p>
                {/* <p>
                  In today&apos;s digital landscape, both data and data sources are expanding at an unprecedented rate. It is widely acknowledged that data is a valuable asset; however, extracting meaningful insights and driving business value can be a daunting task for many organizations. This challenge stems from the inherent complexity of Big Data, characterized by its variability, velocity,
                  and volume, as well as the difficulty in provisioning and maintaining the necessary infrastructure for data ingestion, processing, and visualization. At eCav, our skilled engineering team possesses the technical expertise required to establish an infrastructure capable of managing petabytes of data from multiple sources on a daily basis. Additionally, our team excels in crafting
                  visually appealing and informative dashboards that provide actionable insights for decision-makers, enabling them to unlock the full potential of their data assets.
                </p> */}
                <CloudAndDevOpsCarousel carouselData={imgDataPipelines} />
              </Element>
              <Element name="ci-cd" style={{ marginBottom: "50px" }}>
                <h2>Continuous Integration and Continuous Deployment Services by eCav</h2>
                <p>
                  Our DevOps team has wide experience of building next-generation CI/CD pipelines that help development teams achieve blue/green, red/black and canary deployments, making sure new application versions are deployed with zero downtime. We also have experience in integrating data pipelines into a CI/CD system, allowing for rapid and low-touch change process for what has traditionally
                  been a slow and high touch area. Engineered a cloud migration for a gaming platform, using Kubernetes and Docker, optimizing operational costs and improving player satisfaction.
                </p>
                {/* <p>
                  In the dynamic world of software development, where rapid user feedback on features is essential, the implementation of CI/CD has become a critical component of any development project. Well-designed CI/CD systems streamline the software development process by automating the build, test, and deployment processes of applications, bridging the gap between development and
                  operational activities. At eCav, our proficient DevOps team has extensive experience in constructing cutting-edge CI/CD pipelines that enable development teams to achieve blue/green, red/black, and canary deployments, ensuring seamless deployment of new application versions with zero downtime. Furthermore, our team is adept at integrating data pipelines into CI/CD systems,
                  promoting a swift and low-touch change process for an area that has traditionally been slow and labor-intensive.
                </p> */}
                <CloudAndDevOpsCarousel carouselData={imgCiCd} />
              </Element>
              <Element name="monitoring-and-logging" style={{ marginBottom: "50px" }}>
                <h2>Monitoring and Logging Services by eCav</h2>
                <p>
                  Effective monitoring and logging setups are crucial for cloud-native architectures to facilitate prompt responses and root-cause analysis amidst the complexities of distributed systems. A comprehensive and user-friendly monitoring and logging infrastructure enhances incident response efficiency. At eCav, our skilled engineers have established centralized monitoring and logging
                  systems for small, medium, and enterprise-scale applications. We can swiftly configure dashboarding and alerting capabilities to keep your operational teams informed about your system&apos;s status, enabling rapid root-cause analysis and the implementation of corrective measures to ensure SLA compliance.
                </p>
                <CloudAndDevOpsCarousel carouselData={imagesMoniLog} />
              </Element>
              <Element name="cost-optimization" style={{ marginBottom: "50px" }}>
                <h2>Cost Optimization by eCav</h2>
                {/* <img src={image3} alt="" /> */}
                <p>
                  Efficient management of cloud infrastructure costs is essential for any company deploying solutions in the cloud, as cloud workloads often have excess resources that can adversely impact profitability. With most cloud resources just a few clicks away, it&apos;s crucial to address these challenges. eCav&apos;s certified engineers utilize the best practices of Well-Architected
                  Frameworks (WAFs) to optimize infrastructure resources. Our optimization strategies include usage-adaptive resource utilization, auditing existing resource inventories, and setting up billing thresholds and automated alerts to ensure you stay in control of your cloud expenses.
                </p>
                <CloudAndDevOpsCarousel carouselData={imgCostOpt} />
              </Element>
              <Element name="security" style={{ marginBottom: "50px" }}>
                <h2>Security by eCav</h2>
                <p>
                  As organizations adopt a &quot;cloud-first&quot; strategy to achieve transformational agility and scalability in the public cloud, security concerns can arise with each new instance. A potential security storm looms if the proper measures are not put in place. eCav&apos;s cloud engineers are skilled in conducting comprehensive security analyses of your infrastructure and
                  implementing robust security measures. This ensures minimized security risks while maintaining data privacy and integrity, keeping your organization&apos;s cloud environment secure and protected.
                </p>
                <CloudAndDevOpsCarousel carouselData={imgSecurity} />
              </Element>
              <blockquote>&quot;Our focus is on building the future with our clients, not just building software.&quot;</blockquote>
            </div>
            <div className="faq-content pl-0 pl-md-4">
              <h2>Common Questions for Our Services:</h2>
              <div className="faq-accordion">
                <div className="accordion no-border me-lg-5" id="accordion">
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq1" aria-expanded="false" aria-controls="faq1">
                        What is cloud computing and how can it help my business?
                      </button>
                    </h4>
                    <div id="faq1" className="accordion-collapse collapse show" data-bs-parent="#accordion">
                      <div className="accordion-body">Cloud computing allows businesses to modernize their applications, infrastructure, and operations by leveraging the power of the cloud. Our team can help you migrate to the cloud, implement DevOps practices, and manage your cloud infrastructure to optimize your business processes and achieve your goals.</div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#faq2" aria-expanded="true" aria-controls="faq2">
                        What is Cloud Native Architecture and how can it benefit my business?
                      </button>
                    </h4>
                    <div id="faq2" className="accordion-collapse collapse" data-bs-parent="#accordion">
                      <div className="accordion-body">
                        Cloud Native Architecture is a modern approach to application design and development that leverages the power of cloud computing to create reliable, resilient, and cost-effective solutions with a quicker time to market compared to traditional architectures. At eCav, our cloud-certified architects can collaborate with your team to either develop a cloud-native architecture
                        from the ground up or redesign your current application architecture to meet desired objectives. By decoupling architectural components, this approach enables continuous enhancement and deployment of your product without any downtime. We can rapidly pinpoint areas for improvement in your existing architecture and suggest cloud-native components such as pub-sub, streaming,
                        microservices, and serverless technologies, all of which can help your business scale more efficiently and effectively.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq3" aria-expanded="false" aria-controls="faq3">
                        What are Data Pipelines and how can they help my business?
                      </button>
                    </h4>
                    <div id="faq3" className="accordion-collapse collapse" data-bs-parent="#accordion">
                      <div className="accordion-body">
                        Data Pipelines are automated systems that allow you to collect, process, and store large amounts of data from various sources in a structured and efficient manner. This allows you to gain valuable insights from your data and make informed business decisions. At our company, we specialize in designing and building custom data pipelines that meet the unique needs of our
                        clients.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq4" aria-expanded="false" aria-controls="faq4">
                        What kind of data sources can you work with?
                      </button>
                    </h4>
                    <div id="faq4" className="accordion-collapse collapse" data-bs-parent="#accordion">
                      <div className="accordion-body">We can work with a wide range of data sources, including but not limited to: relational databases, flat files, cloud-based storage, and streaming data. Our team has experience working with a variety of industries and can handle data from any source.</div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq5" aria-expanded="false" aria-controls="faq5">
                        How do you ensure data security?
                      </button>
                    </h4>
                    <div id="faq5" className="accordion-collapse collapse" data-bs-parent="#accordion">
                      <div className="accordion-body">We take data security very seriously and follow best practices to ensure the confidentiality, integrity, and availability of our clients&apos; data. We implement security measures such as encryption, access controls, and regular backups to ensure the safety of your data.</div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq6" aria-expanded="false" aria-controls="faq6">
                        What kind of data processing can you perform?
                      </button>
                    </h4>
                    <div id="faq6" className="accordion-collapse collapse" data-bs-parent="#accordion">
                      <div className="accordion-body">We can perform a range of data processing tasks, including but not limited to: data transformation, cleansing, normalization, and aggregation. Our team will work closely with you to determine the best processing techniques for your data.</div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq7" aria-expanded="false" aria-controls="faq7">
                        Can you provide ongoing support and maintenance for our data pipeline?
                      </button>
                    </h4>
                    <div id="faq7" className="accordion-collapse collapse" data-bs-parent="#accordion">
                      <div className="accordion-body">Yes, we provide ongoing support and maintenance for our data pipelines to ensure that they continue to meet your evolving business needs. Our team is always available to provide assistance and answer any questions you may have.</div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq8" aria-expanded="false" aria-controls="faq8">
                        What is Continuous Integration and Continuous Deployment (CI/CD) and how can it help my business?
                      </button>
                    </h4>
                    <div id="faq8" className="accordion-collapse collapse" data-bs-parent="#accordion">
                      <div className="accordion-body">
                        CI/CD is a software development approach that automates the build, test, and deployment processes of applications, enabling rapid iteration and delivery of new features to end-users. At eCav, we design and build CI/CD pipelines that streamline the software development process, enabling development teams to achieve blue/green, red/black, and canary deployments with zero
                        downtime. This promotes faster time-to-market, higher quality software, and more frequent releases.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq9" aria-expanded="false" aria-controls="faq9">
                        How do you ensure data security in CI/CD pipelines?
                      </button>
                    </h4>
                    <div id="faq9" className="accordion-collapse collapse" data-bs-parent="#accordion">
                      <div className="accordion-body">
                        We take data security very seriously and follow best practices to ensure the confidentiality, integrity, and availability of our clients&apos; data. We implement security measures such as encryption, access controls, and regular backups to ensure the safety of your data. In addition, we work closely with our clients to ensure that their data security requirements are met
                        throughout the CI/CD process.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq10" aria-expanded="false" aria-controls="faq10">
                        How long does it take to set up a CI/CD pipeline?
                      </button>
                    </h4>
                    <div id="faq10" className="accordion-collapse collapse" data-bs-parent="#accordion">
                      <div className="accordion-body">The time it takes to set up a CI/CD pipeline depends on the complexity of your application and the scope of the project. Our team will work closely with you to develop a customized solution that meets your needs and fits your timeline.</div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq11" aria-expanded="false" aria-controls="faq11">
                        What kind of deployments can you handle?
                      </button>
                    </h4>
                    <div id="faq11" className="accordion-collapse collapse" data-bs-parent="#accordion">
                      <div className="accordion-body">Our team has extensive experience in constructing cutting-edge CI/CD pipelines that enable development teams to achieve blue/green, red/black, and canary deployments, ensuring seamless deployment of new application versions with zero downtime.</div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq12" aria-expanded="false" aria-controls="faq12">
                        Do you integrate data pipelines into CI/CD systems?
                      </button>
                    </h4>
                    <div id="faq12" className="accordion-collapse collapse" data-bs-parent="#accordion">
                      <div className="accordion-body">Yes, our team is adept at integrating data pipelines into CI/CD systems, promoting a swift and low-touch change process for an area that has traditionally been slow and labor-intensive. This enables faster data processing and analysis, which in turn can lead to more informed business decisions.</div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq12" aria-expanded="false" aria-controls="faq12">
                        What are Monitoring and Logging Services and how can they help my business?
                      </button>
                    </h4>
                    <div id="faq12" className="accordion-collapse collapse" data-bs-parent="#accordion">
                      <div className="accordion-body">
                        Monitoring and Logging Services are systems that enable you to track the performance and health of your applications in real-time. At eCav, we design and implement comprehensive and user-friendly monitoring and logging infrastructures that enhance incident response efficiency. This ensures that your operational teams are informed about your system&apos;s status, enabling
                        rapid root-cause analysis and the implementation of corrective measures to ensure SLA compliance.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq13" aria-expanded="false" aria-controls="faq13">
                        What are Monitoring and Logging Services and how can they help my business?
                      </button>
                    </h4>
                    <div id="faq13" className="accordion-collapse collapse" data-bs-parent="#accordion">
                      <div className="accordion-body">
                        Monitoring and Logging Services are systems that enable you to track the performance and health of your applications in real-time. At eCav, we design and implement comprehensive and user-friendly monitoring and logging infrastructures that enhance incident response efficiency. This ensures that your operational teams are informed about your system&apos;s status, enabling
                        rapid root-cause analysis and the implementation of corrective measures to ensure SLA compliance.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq14" aria-expanded="false" aria-controls="faq14">
                        How do you establish centralized monitoring and logging systems?
                      </button>
                    </h4>
                    <div id="faq14" className="accordion-collapse collapse" data-bs-parent="#accordion">
                      <div className="accordion-body">Our skilled engineers have experience in establishing centralized monitoring and logging systems for small, medium, and enterprise-scale applications. We work closely with our clients to understand their specific needs and develop customized solutions that meet their requirements.</div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq15" aria-expanded="false" aria-controls="faq15">
                        What kind of dashboarding and alerting capabilities do you configure?
                      </button>
                    </h4>
                    <div id="faq15" className="accordion-collapse collapse" data-bs-parent="#accordion">
                      <div className="accordion-body">We can swiftly configure dashboarding and alerting capabilities that keep your operational teams informed about your system&apos;s status. This includes customized dashboards that provide real-time performance metrics, as well as alerting capabilities that notify your team of potential issues before they escalate.</div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq16" aria-expanded="false" aria-controls="faq16">
                        How long does it take to set up a Monitoring and Logging infrastructure?
                      </button>
                    </h4>
                    <div id="faq16" className="accordion-collapse collapse" data-bs-parent="#accordion">
                      <div className="accordion-body">The time it takes to set up a Monitoring and Logging infrastructure depends on the complexity of your application and the scope of the project. Our team will work closely with you to develop a customized solution that meets your needs and fits your timeline.</div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq17" aria-expanded="false" aria-controls="faq17">
                        What is Cost Optimization and how can it help my business?
                      </button>
                    </h4>
                    <div id="faq17" className="accordion-collapse collapse" data-bs-parent="#accordion">
                      <div className="accordion-body">
                        Cost Optimization is the process of identifying and reducing excess costs in cloud infrastructure, which can adversely impact profitability. At eCav, we utilize the best practices of Well-Architected Frameworks (WAFs) to optimize infrastructure resources. Our optimization strategies include usage-adaptive resource utilization, auditing existing resource inventories, and
                        setting up billing thresholds and automated alerts to ensure you stay in control of your cloud expenses.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq18" aria-expanded="false" aria-controls="faq18">
                        How do you ensure that our infrastructure resources are being utilized efficiently?
                      </button>
                    </h4>
                    <div id="faq18" className="accordion-collapse collapse" data-bs-parent="#accordion">
                      <div className="accordion-body">Our certified engineers use best practices to ensure that infrastructure resources are being utilized efficiently. We employ usage-adaptive resource utilization, which allows us to adjust resource allocation based on actual usage patterns. This enables us to identify excess resources and reduce costs by rightsizing infrastructure.</div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq19" aria-expanded="false" aria-controls="faq19">
                        How do you ensure that our infrastructure resources are being utilized efficiently?
                      </button>
                    </h4>
                    <div id="faq19" className="accordion-collapse collapse" data-bs-parent="#accordion">
                      <div className="accordion-body">Our certified engineers use best practices to ensure that infrastructure resources are being utilized efficiently. We employ usage-adaptive resource utilization, which allows us to adjust resource allocation based on actual usage patterns. This enables us to identify excess resources and reduce costs by rightsizing infrastructure.</div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq20" aria-expanded="false" aria-controls="faq20">
                        How do you set up billing thresholds and automated alerts to ensure we stay in control of our cloud expenses?
                      </button>
                    </h4>
                    <div id="faq20" className="accordion-collapse collapse" data-bs-parent="#accordion">
                      <div className="accordion-body">We set up billing thresholds and automated alerts to ensure that you stay in control of your cloud expenses. This includes setting up billing alerts when costs exceed a certain threshold, as well as automating the shutdown of idle resources to avoid unnecessary charges.</div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq21" aria-expanded="false" aria-controls="faq21">
                        How long does it take to see the benefits of cost optimization?
                      </button>
                    </h4>
                    <div id="faq21" className="accordion-collapse collapse" data-bs-parent="#accordion">
                      <div className="accordion-body">The time it takes to see the benefits of cost optimization depends on the complexity of your cloud infrastructure and the scope of the project. However, our optimization strategies are designed to deliver measurable cost savings in a relatively short amount of time.</div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq22" aria-expanded="false" aria-controls="faq22">
                        How long does it take to see the benefits of cost optimization?
                      </button>
                    </h4>
                    <div id="faq22" className="accordion-collapse collapse" data-bs-parent="#accordion">
                      <div className="accordion-body">The time it takes to see the benefits of cost optimization depends on the complexity of your cloud infrastructure and the scope of the project. However, our optimization strategies are designed to deliver measurable cost savings in a relatively short amount of time.</div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq23" aria-expanded="false" aria-controls="faq23">
                        What is Security by eCav and how can it help my business?
                      </button>
                    </h4>
                    <div id="faq23" className="accordion-collapse collapse" data-bs-parent="#accordion">
                      <div className="accordion-body">
                        Security by eCav is a suite of security services designed to help organizations secure their cloud environments. As organizations adopt a &quot;cloud-first&quot; strategy to achieve transformational agility and scalability in the public cloud, security concerns can arise with each new instance. eCav&apos;s cloud engineers are skilled in conducting comprehensive security
                        analyses of your infrastructure and implementing robust security measures. This ensures minimized security risks while maintaining data privacy and integrity, keeping your organization&apos;s cloud environment secure and protected.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq24" aria-expanded="false" aria-controls="faq24">
                        What kind of security measures do you implement?
                      </button>
                    </h4>
                    <div id="faq24" className="accordion-collapse collapse" data-bs-parent="#accordion">
                      <div className="accordion-body">We implement a variety of security measures to ensure the security of your cloud environment. This includes identity and access management, encryption, network security, and application security. We work closely with our clients to develop customized security solutions that meet their specific needs.</div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq25" aria-expanded="false" aria-controls="faq25">
                        How do you conduct a security analysis of our infrastructure?
                      </button>
                    </h4>
                    <div id="faq25" className="accordion-collapse collapse" data-bs-parent="#accordion">
                      <div className="accordion-body">Our skilled engineers conduct a comprehensive security analysis of your infrastructure, including identifying potential vulnerabilities, assessing risk, and implementing security measures. We use industry-standard tools and methodologies to ensure the highest level of security for your cloud environment.</div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq26" aria-expanded="false" aria-controls="faq26">
                        What steps do you take to maintain data privacy and integrity?
                      </button>
                    </h4>
                    <div id="faq26" className="accordion-collapse collapse" data-bs-parent="#accordion">
                      <div className="accordion-body">We take data privacy and integrity very seriously and follow best practices to ensure the confidentiality, integrity, and availability of our clients&apos; data. We implement security measures such as encryption, access controls, and regular backups to ensure the safety of your data.</div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq27" aria-expanded="false" aria-controls="faq27">
                        How long does it take to implement security measures?
                      </button>
                    </h4>
                    <div id="faq27" className="accordion-collapse collapse" data-bs-parent="#accordion">
                      <div className="accordion-body">The time it takes to implement security measures depends on the complexity of your infrastructure and the scope of the project. However, our skilled engineers work efficiently to ensure that security measures are implemented as quickly as possible without compromising the quality of the solution.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Details;
