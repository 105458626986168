import { v4 as uuidv4 } from "uuid";

import bg1 from "../../assets/img/networkAppBg/network-infra-compressed.jpg";
import bg2 from "../../assets/img/networkAppBg/network-config-compressed.jpg";
import bg3 from "../../assets/img/networkAppBg/network-security-compressed.jpg";
import bg4 from "../../assets/img/networkAppBg/network-auto-compressed.jpg";
import bg5 from "../../assets/img/networkAppBg/network-troubleshoot-compressed.jpg";
// import bg6 from "../../assets/img/networkAppBg/network-validate-compressed.jpg";
// import bg7 from "../../assets/img/networkAppBg/cloud-virtual-compressed.jpg";

const servicesOneData = [
  {
    id: uuidv4(),
    bg: bg1,
    icon: "cloud-server2",
    title: "Smart Contracts for Renewable Energyg",
    desc:
      "Implemented a smart contract solution for a renewable energy trading platform using Hyperledger Fabric, enhancing the efficiency of green energy distribution."
  },
  {
    id: uuidv4(),
    bg: bg2,
    icon: "network-deploy",
    title: "Blockchain Consulting and Advisory",
    desc:
      "Unlock blockchain's potential for your business. Our consultants offer expert advice on adoption, strategy, use cases, and tech selection."
  },
  {
    id: uuidv4(),
    bg: bg3,
    icon: "netwrok-security",
    title: "Blockchain Integration Services",
    desc:
      "Seamlessly integrate blockchain into your systems. We ensure interoperability and efficiency with supply chain, identity, and payment integrations."
  },
  {
    id: uuidv4(),
    bg: bg4,
    icon: "network-auto",
    title: "Blockchain Security Audits",
    desc:
      "Ensure blockchain app security with thorough audits. Identify vulnerabilities, bugs, and risks for enhanced protection."
  },
  {
    id: uuidv4(),
    bg: bg5,
    icon: "network-troubleshoot",
    title: "Blockchain Training and Workshops",
    desc:
      "Empower your team with blockchain training. Customized sessions cover fundamentals, smart contracts, DApp development, and more."
  }
];

export default servicesOneData;
