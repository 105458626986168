import { Link } from 'react-router-dom';
import designUXData from './designUXData';
import 'swiper/swiper-bundle.css';

import { ReactComponent as Icon1 } from '../../assets/img/icons/design-ux-icons/dashboards.svg';
import { ReactComponent as Icon2 } from '../../assets/img/icons/design-ux-icons/custom-visual.svg';
import { ReactComponent as Icon3 } from '../../assets/img/icons/design-ux-icons/input-intensive.svg';
import { ReactComponent as Icon4 } from '../../assets/img/icons/design-ux-icons/videos.svg';

const NetworkApps = () => {
  const getIconComponent = (iconName, width = 75, height = 75) => {
    switch (iconName) {
      case 'dashboards':
        return <Icon1 width={width} height={height} />;
      case 'custom-visual':
        return <Icon2 width={width} height={height} />;
      case 'input-intensive':
        return <Icon3 width={width} height={height} />;
      case 'videos':
        return <Icon4 width={width} height={height} />;
      default:
        return null;
    }
  };

  return (
    <section className="our-service-wrapper section-padding bg-gray">
      <div className="container">
        <div className="row mtm-30">
          <div className="col-md-6 col-12 col-lg-4 mt-30">
            <div className="block-contents">
              <div className="section-title">
                <span>Deisgn, UX</span>
                <h3>
                  Crafting Unforgettable Details through Perfectly Designed
                  Pixels and Flawless User Experience
                </h3>
              </div>
              <p>
                At eCav, we believe that the key to making details memorable is
                through the creation of pixel-perfect designs and a smooth user
                experience. With our human-centered approach and expertise in
                design and technology, we produce custom visualizations,
                dashboards, input-intensive applications, and videos that not
                only solve design challenges but also bring joy to people.
              </p>
            </div>
          </div>
          {designUXData.map((data) => (
            <div className="col-md-6 col-12 col-lg-4 mt-30" key={data.id}>
              <div className="single-service-box style-1">
                <div
                  className="service-bg bg-cover"
                  style={{
                    backgroundImage: `url(${data.bg})`
                  }}
                ></div>
                <div className="icon">{getIconComponent(data.icon)}</div>
                <div
                  className="contents d-flex align-items-center justify-content-center"
                  // style={{ height: '200px' }}
                >
                  <h4>
                    <Link to="/servicesDetails">{data.title}</Link>
                  </h4>
                  <p>{data.desc}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default NetworkApps;
