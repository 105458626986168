import { Link } from "react-router-dom";
import servicesThreeData from "./servicesThreeData";

const ServicesThree = () => {
  return (
    <section className="our-services-box-wrapper service-padding bg-gray">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-8 mb-4 offset-xl-3 offset-lg-2 col-12 text-center">
            <div className="section-title-3">
              <h3>Services</h3>
              {/* <h6>Our Services</h6>
                            <h2>What solutions we offer your</h2> */}
            </div>
          </div>
        </div>
        <div className="row">
          {servicesThreeData.map((data) => (
            <div className="col-xl-3 col-md-6" key={data.id}>
              <div className="single-services-box">
                <div className="icon">
                  {/* <img src={data.img} alt="" /> */}
                  <Link to={`/${data.pageName}`}>
                    <img src={data.img} alt="" />
                  </Link>
                </div>
                <div className="content">
                  {/* <h3><Link to="/servicesDetails">{data.title}</Link></h3> */}
                  {/* <h3><Link to={`/servicesDetails/${data.id}`}>{data.title}</Link></h3> */}
                  <Link to={`/${data.pageName}`}>{data.title}</Link>
                  <p className="service-description">{data.desc}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesThree;
