import React from 'react'
import FooterTwo from '../Components/Footer/FooterTwo'
import HeaderThree from '../Components/Header/HeaderThree'
import bannerBg from '../assets/img/Network-app-banner-compressed.jpg';
import PageBanner from '../Components/PageBanner';
import CyberSecurityDetails from '../Components/Services/CyberSecurityDetail';

const ServiceDetails = () => {
  return (
    <>
        <HeaderThree/>
        <PageBanner title='Cyber Security' bannerBg={bannerBg} currentPage='/services/CyberSecurity' bgColor='#f5f5f5' />
        <CyberSecurityDetails/>
        <FooterTwo/>
    </>
  )
}

export default ServiceDetails;