import React from 'react'
import FooterTwo from '../Components/Footer/FooterTwo'
import HeaderThree from '../Components/Header/HeaderThree'
import bannerBg from '../assets/img/GenAiHeader.png';
import PageBanner from '../Components/PageBanner';
import AIServices from '../Components/Services/AIDetails';
// import WebServiceCarousel from "../Components/Brands/WebServiceCarousel";
// import WebDevProjectsTab from "../Components/Projects/WebDevProjectsTab";

const AnalyticsDetails = () => {
  return (
    <>
        <HeaderThree/>
        <PageBanner title='AI & Analytics.' bannerBg={bannerBg} currentPage='/services/AI & Analytics' bgColor='#f5f5f5' />
        <AIServices/>
        {/* <WebServiceCarousel /> */}
        {/* <WebDevProjectsTab /> */}
        <FooterTwo/>
    </>
  )
}

export default AnalyticsDetails;