// import { Link } from 'react-router-dom';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useState } from 'react';

const ContactPageContents = () => {
    const [submitStatus, setSubmitStatus] = useState(null);
    const [submitMessage, setSubmitMessage] = useState('');

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        mode: 'onBlur'
    });

    const submitEmail = async (data) => {
        try {
            // Authenticate with the /authenticate endpoint
            const authResponse = await axios.post(
                `${process.env.REACT_APP_API_URL}/authenticate`,
                {
                    username: process.env.REACT_APP_API_USERNAME,
                    password: process.env.REACT_APP_API_PASSWORD
                }
            );

            // Obtain the JWT token from the response
            const token = authResponse.data.token;

            // Send email using the /send endpoint with the JWT token
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/send`,
                {
                    mailerState: data
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            if (response.data.success === true) {
                setSubmitStatus('success');
                setSubmitMessage('Message Sent');
            } else if (response.data.status === 'fail') {
                setSubmitStatus('fail');
                setSubmitMessage('Message failed to   send');
            }
        } catch (error) {
            setSubmitStatus('error');
            setSubmitMessage('Error occurred while sending email');
            console.error('Error occurred while sending email:', error);
        }
    };

    return (
        <div className="contact-us-wrapper section-padding">
            <div className="container">
                <div className="row eq-height">
                    <div className="col-lg-8 col-12">
                        <div className="contact-form">
                            {submitStatus
? (
                                <div
                                    className={`submit-status ${submitStatus}`}
                                >
                                    {submitMessage}
                                </div>
                            )
: (
                                <form
                                    onSubmit={handleSubmit(submitEmail)}
                                    className="row"
                                >
                                    <h2>Get in Touch</h2>
                                    <div className="col-md-6 col-12">
                                        <div className="single-personal-info">
                                            <input
                                                type="text"
                                                name="name"
                                                placeholder="Name"
                                                {...register('name', {
                                                    required: true
                                                })}
                                            />
                                            {errors.name && (
                                                <div className="error-message">
                                                    Name is required
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div className="single-personal-info">
                                            <input
                                                type="email"
                                                name="email"
                                                placeholder="Email"
                                                {...register('email', {
                                                    required: true
                                                })}
                                            />
                                        </div>
                                        {errors.email && (
                                            <div className="error-message">
                                                Email is required
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div className="single-personal-info">
                                            <input
                                                type="text"
                                                name="phone"
                                                placeholder="Number"
                                                {...register('phone', {
                                                    required: true
                                                })}
                                            />
                                        </div>
                                        {errors.phone && (
                                            <div className="error-message">
                                                Number is required
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div className="single-personal-info">
                                            <input
                                                type="text"
                                                name="subject"
                                                placeholder="Subject"
                                                {...register('subject', {
                                                    required: true
                                                })}
                                            />
                                        </div>
                                        {errors.subject && (
                                            <div className="error-message">
                                                Subject is required
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-12 col-12">
                                        <div className="single-personal-info">
                                            <textarea
                                                name="message"
                                                placeholder="Message"
                                                {...register('message', {
                                                    required: true
                                                })}
                                            />
                                        </div>
                                        {errors.message && (
                                            <div className="error-message">
                                                Message is required
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-md-12 col-12">
                                        <input
                                            className="submit-btn"
                                            type="submit"
                                            value="Submit Now"
                                            onClick={handleSubmit(submitEmail)}
                                        />
                                    </div>
                                </form>
                            )}
                            <span className="form-message" />
                        </div>
                    </div>
                    <div className="col-lg-4 col-12">
                        <div className="contact-us-sidebar mt-5 mt-lg-0">
                            <div className="contact-info">
                                <h2>CONTACT INFO</h2>
                                <div className="single-info">
                                    <div className="icon">
                                        <i className="flaticon-email" />
                                    </div>
                                    <div className="text">
                                        <span>Email Us</span>
                                        <h5>info@ecavtechnologies.com</h5>
                                    </div>
                                </div>
                                <div className="single-info">
                                    <div className="icon">
                                        <i className="flaticon-phone-call-1" />
                                    </div>
                                    <div className="text">
                                        <span>Call Us</span>
                                        <h5>+1 202 714 4236 </h5>
                                        <h5>+92 318 530 1008</h5>
                                    </div>
                                </div>
                                <div className="single-info">
                                    <div className="icon">
                                        <i className="flaticon-pin" />
                                    </div>
                                    <div className="text">
                                        <span>Location</span>
                                        <h5>
                                            23266 Southdown Loudoun Valley
                                            <br /> Ashburn VA 20148, USA
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            {/*                            <div className="live-chat">
                                <h2>Live Chat</h2>
                                <p>You can contact us on chat and explain
                                your problems</p>
                                <Link to="/about"><i className="flaticon-chatting" /> Live Chat to Executive</Link>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactPageContents;