import React from 'react'
import FooterTwo from '../Components/Footer/FooterTwo'
import HeaderThree from '../Components/Header/HeaderThree'
import bannerBg from '../assets/img/team/colleagues-giving-fist-bump-compressed.jpg';
import PageBanner from '../Components/PageBanner';
import TeamTwo from "../Components/Team/TeamTwo";
// import TeamOne from "../Components/Team/TeamOne";

const TeamPage = () => {
  return (
    <>
        <HeaderThree/>
        <PageBanner title='Our Team' bannerBg={bannerBg} currentPage='Team' />
        <TeamTwo/>

        <FooterTwo/>
    </>
  )
}

export default TeamPage