import React from "react";
// import FooterTwo from '../Components/Footer/FooterTwo'
import FooterTwo from "../Components/Footer/FooterTwo";
import HeaderThree from "../Components/Header/HeaderThree";
// import bannerBg from '../assets/img/triangular-figure-neon-1920x500.jpg';
import bannerBg from "../assets/img/washington.jpg";
import PageBanner from "../Components/PageBanner";
import AboutTwo from "../Components/About/AboutTwo";
import AboutBanner from "../Components/About/AboutBanner";
// import Timeline from '../Components/Timeline';
// import TeamTwo from "../Components/Team/TeamTwo";
// import BrandCarouselFour from "../Components/Brands/BrandCarouselFour";
import Skill from "../Components/Skills/Skill";
// import CoreFeatures from "../Components/Services/CoreFeatures";

const AboutPage = () => {
  return (
    <>
      <HeaderThree />
      <PageBanner title="About us" bannerBg={bannerBg} currentPage="About Us" bgColor="#f5f5f5" headingClass="text-white" breadcrumbColor="text-white" breadcrumbIconColor="#fff" />
      <AboutTwo pt />
      <AboutBanner />

      <Skill />
      {/* <CoreFeatures /> */}
      {/* <TeamTwo /> */}
      {/* <BrandCarouselFour /> */}
      <FooterTwo />
    </>
  );
};

export default AboutPage;
