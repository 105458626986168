// import { isValidElement } from 'react';
import { v4 as uuidv4 } from 'uuid';

const faqOneData = [
    {
        id: uuidv4(),
        ques: 'What is cloud computing and how can it help my business?',
        ans: 'Cloud computing allows businesses to modernize their applications, infrastructure, and operations by leveraging the power of the cloud. Our team can help you migrate to the cloud, implement DevOps practices, and manage your cloud infrastructure to optimize your business processes and achieve your goals.',
        index: 'faq1'
    },

    {
        id: uuidv4(),
        ques: 'What is Cloud Native Architecture and how can it benefit my business?',
        ans: 'We have intentionally never developed a stylistic formula for our work, which is why our projects never quite look or feel the same. Each of our spaces is the result of our ability to listen.',
        index: 'faq2'
    },

    {
        id: uuidv4(),
        ques: 'How can your quality assurance services benefit my software development process',
        ans: 'Our team of experienced QA professionals can help you build a comprehensive QA strategy and implement effective test automation practices to deliver high-quality software. By ensuring quality throughout the software development process, we can help you reduce costs, minimize errors, and enhance customer satisfaction.',
        index: 'faq3'
    },

    {
        id: uuidv4(),
        ques: 'How can your network application services help my enterprise network performance?',
        ans: 'Our network engineers can help you design, implement, and manage your network infrastructure to ensure optimal performance and reliability. We can configure and troubleshoot your enterprise networks and provide 24/7 network monitoring to identify and resolve issues before they become critical.',
        index: 'faq4'
    },

    {
        id: uuidv4(),
        ques: 'How can your design and UX services enhance my product?',
        ans: 'Our team of experienced designers can help you create a user-centered design that enhances the functionality and accessibility of your product. By focusing on the user experience, we can help you create a product that not only looks visually appealing but also makes it easy for your customers to use and engage with. This can lead to increased customer satisfaction and engagement, which in turn can improve your business performance.',
        index: 'faq5'
    }

]

export default faqOneData;