import { v4 as uuidv4 } from 'uuid';
// import brand1 from '../../assets/img/brand-logo/1-ecav.png';
import brand2 from '../../assets/img/brand-logo/2-ecav.png';
import brand3 from '../../assets/img/brand-logo/3-ecav.png';
import brand4 from '../../assets/img/brand-logo/4-ecav.png';
import brand5 from '../../assets/img/brand-logo/5-ecav.png';
import brand6 from '../../assets/img/brand-logo/6-ecav.png';
import brand7 from '../../assets/img/brand-logo/7-ecav.png';
import brand8 from '../../assets/img/brand-logo/8-ecav.png';
import brand9 from '../../assets/img/brand-logo/9-ecav.png';
import brand10 from '../../assets/img/brand-logo/10-ecav.png';
import brand11 from '../../assets/img/brand-logo/rga.png';
import brand12 from '../../assets/img/brand-logo/riyadh.png';
import brand13 from '../../assets/img/brand-logo/difc.png';
import brand14 from '../../assets/img/brand-logo/ted.png';
import brand15 from '../../assets/img/brand-logo/ge.png';
// import brand11 from '../../assets/img/brand-logo/11-ecav.png';

const brandCarouselOneData = [

    {
        id: uuidv4(),
        brand: brand2,
        name: 'Extreme Networks'
    },

    {
        id: uuidv4(),
        brand: brand3,
        name: 'Microsoft'
    },

    {
        id: uuidv4(),
        brand: brand4,
        name: 'One Market'
    },

    {
        id: uuidv4(),
        brand: brand5,
        name: 'DVSUM'
    },

    {
        id: uuidv4(),
        brand: brand6,
        name: 'LEXSTEP'
    },
    {
        id: uuidv4(),
        brand: brand7,
        name: 'Twitch'
    },
    {
        id: uuidv4(),
        brand: brand8,
        name: 'FanKave'
    },
    {
        id: uuidv4(),
        brand: brand9,
        name: 'Fetch.ai'
    },
    {
        id: uuidv4(),
        brand: brand10,
        name: 'netnology'
    },
    {
        id: uuidv4(),
        brand: brand11,
        name: 'rga'
    },
    {
        id: uuidv4(),
        brand: brand12,
        name: 'riyadh'
    },
    {
        id: uuidv4(),
        brand: brand13,
        name: 'difc'
    },
    {
        id: uuidv4(),
        brand: brand14,
        name: 'ted'
    },
    {
        id: uuidv4(),
        brand: brand15,
        name: 'ge'
    }

]
// Twitch FanKave Fetch.ai netnology QatarLiving BTS safeKids Capital-BEE BaseballCloud [SKIP] WeGift LiveAction SBM KLOSECARE
export default brandCarouselOneData;