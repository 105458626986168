/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';

const PageBanner = ({
    bannerBg,
    currentPage,
    title,
    bgColor = '#001659',
    headingClass = 'text-white',
    breadcrumbColor = 'text-white',
    breadcrumbIconColor = '#786f80'
}) => {
    const generateBreadcrumbs = () => {
        const path = currentPage.split('/');
        const breadcrumbs = [];

        let currentPath = '';
        for (const segment of path) {
            if (segment !== '') {
                currentPath += `/${segment}`;
                breadcrumbs.push(
                    <li className="breadcrumb-item" key={currentPath}>
                        <Link to={currentPath}>{segment}</Link>
                    </li>
                );
            }
        }
        return breadcrumbs;
    };

    return (
        <div
            className="page-banner-wrap bg-cover"
            style={{
                backgroundImage: `url(${bannerBg})`,
                backgroundColor: bgColor
            }}
        >
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-12">
                        <div className={`page-heading ${headingClass}`}>
                            <h1>{title}</h1>
                        </div>
                        <div className={`breadcrumb-wrap ${breadcrumbColor}`}>
                            <nav>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Home</Link>
                                    </li>
                                    {generateBreadcrumbs()}
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <style>{`
        .page-banner-wrap .breadcrumb li + li::before {
          color: ${breadcrumbIconColor};
          content: "\\f054";
          font-family: "Font Awesome 5 Pro";
          font-size: 14px;
        }
      `}</style>
        </div>
    );
};

export default PageBanner;