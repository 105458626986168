import { Link } from 'react-router-dom';
import cyberAppsData from './cyberAppData';
import 'swiper/swiper-bundle.css';

import { ReactComponent as Icon1 } from '../../assets/img/icons/net-app-icons/banking-business.svg';
import { ReactComponent as Icon2 } from '../../assets/img/icons/net-app-icons/cloud-ser.svg';
import { ReactComponent as Icon3 } from '../../assets/img/icons/net-app-icons/management.svg';
import { ReactComponent as Icon4 } from '../../assets/img/icons/net-app-icons/network-auto.svg';
import { ReactComponent as Icon5 } from '../../assets/img/icons/net-app-icons/network-troubleshoot.svg';
import { ReactComponent as Icon6 } from '../../assets/img/icons/net-app-icons/network-validate.svg';
import { ReactComponent as Icon7 } from '../../assets/img/icons/net-app-icons/cloud-remote.svg';

const CyberApp = () => {
  const getIconComponent = (iconName, width = 75, height = 75) => {
    switch (iconName) {
      case 'cloud-server2':
        return <Icon1 width={width} height={height} />;
      case 'network-deploy':
        return <Icon2 width={width} height={height} />;
      case 'netwrok-security':
        return <Icon3 width={width} height={height} />;
      case 'network-auto':
        return <Icon4 width={width} height={height} />;
      case 'network-troubleshoot':
        return <Icon5 width={width} height={height} />;
      case 'network-validate':
        return <Icon6 width={width} height={height} />;
      case 'cloud-remote':
        return <Icon7 width={width} height={height} />;
      default:
        return null;
    }
  };

  return (
    <section className="our-service-wrapper section-padding bg-gray">
      <div className="container">
        <div className="row mtm-30">
          <div className="col-md-6 col-12 col-lg-4 mt-30">
            <div className="block-contents">
              <div className="section-title">
                <span>Cyber Security</span>
                <h3>Custom Cybersecurity Framework for Government</h3>
              </div>
              <p>
              Developed a custom cybersecurity framework for a government agency, integrating threat intelligence solutions and establishing incident response protocols.
              </p>
            </div>
          </div>
          {cyberAppsData.map((data) => (
            <div className="col-md-6 col-12 col-lg-4 mt-30" key={data.id}>
              <div className="single-service-box style-1">
                <div
                  className="service-bg bg-cover"
                  style={{
                    backgroundImage: `url(${data.bg})`
                  }}
                ></div>
                <div className="icon">{getIconComponent(data.icon)}</div>
                <div
                  className="contents d-flex align-items-center justify-content-center"
                  style={{ height: '200px' }}
                >
                  <h4>
                    <Link to="/servicesDetails">{data.title}</Link>
                  </h4>
                  <p>{data.desc}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CyberApp;