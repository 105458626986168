import { v4 as uuidv4 } from "uuid";
// import bg1 from "../../assets/img/services/1.jpg";

import bg1 from "../../assets/img/webServBg/creative-ideas.jpg";
import bg2 from "../../assets/img/webServBg/dynamic-pages.jpg";
import bg3 from "../../assets/img/webServBg/seo.jpg";
import bg4 from "../../assets/img/webServBg/charts-visual.jpg";
import bg5 from "../../assets/img/webServBg/forms-wiz.jpg";
import bg6 from "../../assets/img/webServBg/optimize.jpg";
import bg7 from "../../assets/img/webServBg/responsive.jpg";
import bg8 from "../../assets/img/webServBg/containers.jpg";

const servicesOneData = [
  {
    id: uuidv4(),
    bg: bg1,
    icon: "creative-idea",
    title: "Creative Ideas",
    desc:
      "At eCav, we use innovative and unique ideas to make your web app stand out using the latest design trends and technologies."
  },
  {
    id: uuidv4(),
    bg: bg2,
    icon: "cubes-svgrepo-com",
    title: "Dynamic Pages",
    desc:
      "With eCav's web development services, we create dynamic web pages that engage your audience and provide an interactive experience using modern front-end technologies and efficient back-end systems."
  },
  {
    id: uuidv4(),
    bg: bg3,
    icon: "seo",
    title: "SEO",
    desc:
      "At eCav, we optimize your web app for search engines to drive organic traffic and increase your visibility in the digital space."
  },
  {
    id: uuidv4(),
    bg: bg4,
    icon: "charts-visual",
    title: "Charts & Visualizations",
    desc:
      "At eCav, we use the right visuals to show the beauty of your data and reflect its true meaning."
  },
  {
    id: uuidv4(),
    bg: bg5,
    icon: "forms-wizards",
    title: "Forms & Wizards",
    desc:
      "With eCav's web development services, we design a user experience that can significantly increase your conversion rate through forms."
  },
  {
    id: uuidv4(),
    bg: bg6,
    icon: "web-optimize",
    title: "Optimization",
    desc:
      "At eCav, we optimize your web applications to run faster and be more engaging. They offer a better visual experience and more interactivity."
  },
  {
    id: uuidv4(),
    bg: bg7,
    icon: "responsive-layout",
    title: "Responsive Layout",
    desc:
      "With eCav's web development services, get an application that looks equally stunning across all devices and platforms because it’s a must-have."
  },
  {
    id: uuidv4(),
    bg: bg8,
    icon: "microservices-arch",
    title: "Microservices Architecture",
    desc:
      "At eCav, we solve complex problems by making multiple, independently developed and maintained systems work together using microservices architecture."
  }
];

export default servicesOneData;
