import { v4 as uuidv4 } from 'uuid';

import brand1 from '../../assets/img/designUX-toolset/adobe-illustrator-cs6-144X144.png';
import brand2 from '../../assets/img/designUX-toolset/adobe-photoshop-cs6-144X144.png';
import brand3 from '../../assets/img/designUX-toolset/adobe-xd-1-144X144.png';
import brand4 from '../../assets/img/designUX-toolset/bootstrap-5-144X144.png';
import brand5 from '../../assets/img/designUX-toolset/css-3-144X144.png';
import brand6 from '../../assets/img/designUX-toolset/figma-5-144X144.png';
import brand7 from '../../assets/img/designUX-toolset/html-5-144X144.png';
import brand8 from '../../assets/img/designUX-toolset/invision-144X144.png';
import brand9 from '../../assets/img/designUX-toolset/javascript-1-144X144.png';
import brand10 from '../../assets/img/designUX-toolset/less-144X144.png';
import brand11 from '../../assets/img/designUX-toolset/marvel-6-144X144.png';
import brand12 from '../../assets/img/designUX-toolset/sass-1-144X144.png';
import brand13 from '../../assets/img/designUX-toolset/sketch-2-144X144.png';
import brand14 from '../../assets/img/designUX-toolset/zeplin-144X144.png';

const brandCarouselOneData = [
    {
        id: uuidv4(),
        brand: brand1,
        name: 'Adobe Illustrator'
    },

    {
        id: uuidv4(),
        brand: brand2,
        name: 'Adobe Photoshop'
    },

    {
        id: uuidv4(),
        brand: brand3,
        name: 'Adobe Xd'
    },

    {
        id: uuidv4(),
        brand: brand4,
        name: 'bootstrap'
    },

    {
        id: uuidv4(),
        brand: brand5,
        name: 'CSS'
    },

    {
        id: uuidv4(),
        brand: brand6,
        name: 'Figma'
    },
    {
        id: uuidv4(),
        brand: brand7,
        name: 'Html 5'
    },
    {
        id: uuidv4(),
        brand: brand8,
        name: 'Invision'
    },
    {
        id: uuidv4(),
        brand: brand9,
        name: 'Javascript'
    },
    {
        id: uuidv4(),
        brand: brand10,
        name: 'Less'
    },
    {
        id: uuidv4(),
        brand: brand11,
        name: 'Marvel'
    },
    {
        id: uuidv4(),
        brand: brand12,
        name: 'SASS'
    },
    {
        id: uuidv4(),
        brand: brand13,
        name: 'Sketch'
    },
    {
        id: uuidv4(),
        brand: brand14,
        name: 'Zeplin'
    }
]

export default brandCarouselOneData;