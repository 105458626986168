import React from 'react'
import FooterTwo from '../Components/Footer/FooterTwo'
import HeaderThree from '../Components/Header/HeaderThree'
import bannerBg from '../assets/img/blockchain.jpg';
import PageBanner from '../Components/PageBanner';
import NetworkAppsDetails from '../Components/Services/NetworkAppsDetails';
// import NetworkAppsCarousel from "../Components/Brands/NetworkAppsCarousel";
// import NetworksProjectsTab from "../Components/Projects/NetworksProjectsTab";

const ServiceDetails = () => {
  return (
    <>
        <HeaderThree/>
        <PageBanner title='Block Chain.' bannerBg={bannerBg} currentPage='/services/BlockChain' bgColor='#f5f5f5' />
        <NetworkAppsDetails/>
        {/* <NetworkAppsCarousel /> */}
        {/* <NetworksProjectsTab /> */}
        <FooterTwo/>
    </>
  )
}

export default ServiceDetails;