import React from 'react'
import FooterTwo from '../Components/Footer/FooterTwo'
import HeaderThree from '../Components/Header/HeaderThree'
import bannerBg from '../assets/img/DevopsHeader.png';
import PageBanner from '../Components/PageBanner';
import CloudAndDevOpsDetails from '../Components/Services/CloudAndDevOpsDetails';

const ServiceDetails = () => {
  return (
    <>
        <HeaderThree/>
        <PageBanner title='Cloud Experts Partner.' bannerBg={bannerBg} currentPage='/services/Cloud & Devops' bgColor='#f5f5f5' />
        <CloudAndDevOpsDetails/>
        <FooterTwo/>
    </>
  )
}

export default ServiceDetails;