import { v4 as uuidv4 } from 'uuid';
import img1 from '../../assets/img/team/s-gillani.jpeg';
import img2 from '../../assets/img/team/k-malik.jpeg';
import img3 from '../../assets/img/team/w-khan.jpeg';
import img4 from '../../assets/img/team/b-salim.jpeg';
import img5 from '../../assets/img/team/u-muzzafar.jpeg';

const teamTwoData = [
    {
        id: uuidv4(),
        img: img1,
        name: 'S. Asim Hamza Gilani',
        designation: 'Chief Executive Officer',
        fb: '#',
        twitter: '#',
        insta: '#',
        linkedIn: 'https://www.linkedin.com/in/asimgilani/'
    },

    {
        id: uuidv4(),
        img: img2,
        name: 'Kanwar J. Malik',
        designation: 'Chief Technical Officer',
        fb: '#',
        twitter: '#',
        insta: '#',
        linkedIn: 'https://www.linkedin.com/in/kanwarmalik/'
    },

    {
        id: uuidv4(),
        img: img3,
        name: 'Waseem Khan',
        designation: 'President',
        fb: '#',
        twitter: '#',
        insta: '#',
        linkedIn: 'https://www.linkedin.com/in/waseem-khan-41626339/'
    },

    {
        id: uuidv4(),
        img: img4,
        name: 'Bilal Salim',
        designation: 'Chief Advisor',
        fb: '#',
        twitter: '#',
        insta: '#',
        linkedIn: 'https://www.linkedin.com/in/bilalsalim/'
    },

    {
        id: uuidv4(),
        img: img5,
        name: 'Usman Muzaffar',
        designation: 'Chief Revenue Officer',
        fb: '#',
        twitter: '#',
        insta: '#',
        linkedIn: 'https://www.linkedin.com/in/usmanmuzaffar/'
    }

]

export default teamTwoData;