import { v4 as uuidv4 } from "uuid";

import bg1 from "../../assets/img/cyberFinance.jpg";
import bg2 from "../../assets/img/Cyber-security-solution.jpg";
import bg3 from "../../assets/img/cyber-tool.jpg";

const servicesOneData = [
  {
    id: uuidv4(),
    bg: bg1,
    icon: "cloud-server2",
    title: "Cybersecurity Solution for Finance",
    desc:
      "Delivered a cybersecurity solution for a financial institution, including an SOC with Splunk for real-time monitoring and advanced behavioral analytics."
  },
  {
    id: uuidv4(),
    bg: bg2,
    icon: "network-deploy",
    title: "Cloud Storage Solution for Legal Firm",
    desc:
      "Built a proprietary cloud storage solution for a legal firm with AES encryption and redundancy protocols, ensuring GDPR compliance."
  },
  {
    id: uuidv4(),
    bg: bg3,
    icon: "network-troubleshoot",
    title: "Construction Project Management Tool",
    desc:
      "Crafted a project management tool for a construction company, integrating advanced scheduling algorithms and Node.js and Angular for a microservices architecture."
  }
];

export default servicesOneData;
