import React from 'react';
// import { Link } from 'react-router-dom';
import aboutImg from '../../assets/img/about-image-1.png';

const AboutTwo = () => {
    return (
      <section className="about-section section-padding">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-12 pe-xl-0">
              <div
                className="about-cover-bg bg-cover me-xl-5"
                style={{ backgroundImage: `url(${aboutImg})` }}
              >
                <div className="our-experience-years">
                  <div className="year-outline">
                    <h1>75+</h1>
                  </div>
                  <p>
                    Years <span> Combined Experience</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-6 mt-5 mt-lg-0 col-12">
              <div className="block-contents">
                <div className="section-title">
                  <span>eCav Technologies</span>
                  <h2>
                    &quot;Empowering Innovation, Crafting Solutions&quot;
                  </h2>
                </div>
                <blockquote>
                  eCav: Attracting top talent and tackling complex challenges
                  with 75+ years of combined leadership experience in technology
                  development and product design.
                </blockquote>
              </div>

              <p className="block-content-p">
                eCav Technologies is based out of Washington D.C. Our leadership
                team, consisting of Silicon Valley veterans, brings 75+ years of
                cumulative experience in technology development, product design,
                and leadership. Founded in 2020, we have achieved 100% YOY
                growth and are now home to 200 world-class engineers and
                designers. Our focus on both product building and software
                services has created a unique DNA that places equal emphasis on
                product design, user experience, and solid engineering. As a
                customer-focused and quality-driven organization, we use
                industry best practices for continuous improvement, process
                streamlining, and increased quality to exceed your operational
                goals and expectations. Our mission is to serve you with
                responsibility and results, and drive innovation and success
                through leadership. As your strategic partner, we solve your
                most complex business challenges by leveraging technology
                solutions that reduce costs, minimize risk, and optimize
                productivity. Our core expertise are in Software Enterprise
                Application Development & Quality Assurance (QA), Data
                Engineering and Data Sciences (High Volume, High Velocity),
                Med-Tech - EHR/EMR Systems & Prior Authorization Solutions.
              </p>
              {/* <p className='block-content-p'>
                        At eCav, we specialize in creating cutting-edge Data Solutions for businesses of all sizes. Our team of experienced professionals
                        excels in data science, data pipelines, and data engineering, helping you turn your data into insights that drive business success.
                        Whether you need to build a custom data analytics platform, implement machine learning algorithms, or optimize your data
                        infrastructure, we have the expertise to help you achieve your goals. With our data-driven approach and advanced analytics tools, you
                        can gain a deeper understanding of your business and make better decisions that drive growth and profitability.
                        </p>

                        <Link to="/about" className="theme-btn mt-35">
                            read more
                        </Link> */}
            </div>
          </div>
        </div>
        <h2 className="section-rotate-title d-none d-xxl-block">ABOUT</h2>
      </section>
    );
};

export default AboutTwo;