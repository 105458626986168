import React from 'react';
import FooterTwo from '../Components/Footer/FooterTwo';
import HeaderThree from '../Components/Header/HeaderThree';
import bannerBg from '../assets/img/DesignUX-banner-1280X401.jpg';
import PageBanner from '../Components/PageBanner';
import DesignUXDetails from '../Components/Services/DesignUXDetails';
import DesignUXCarousel from '../Components/Brands/DesignUXCarousel';
// import DesignUXTab from '../Components/Projects/DesignUXTab';
// import PortfolioTwo from "../Components/Portfolio/PortfolioTwo";
// import DesignUXTab2 from '../Components/Projects/DesignUXTab2';

const ServiceDetails = () => {
  return (
    <>
      <HeaderThree />
      <PageBanner
        title="UI/UX"
        bannerBg={bannerBg}
        currentPage="/services/DesignUX"
        bgColor="#f5f5f5"
        headingClass="text-white"
        breadcrumbColor="text-white"
        breadcrumbIconColor="#fff"
      />
      <DesignUXDetails />
      <DesignUXCarousel />
      {/* <DesignUXTab /> */}

      <FooterTwo />
    </>
  );
};

export default ServiceDetails;