import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import PropTypes from 'prop-types';

const QualityAssurancCarousel = ({ carouselData }) => {
    return (
        <section className="our-brand-showcase pt-0">
            <div className="container text-center">
                <div className="brand-carousel-2 mt-5">
                    <Swiper
                        slidesPerView={5}
                        spaceBetween={20}
                        loop
                        autoplay={{
                            delay: 4000,
                            disableOnInteraction: false
                        }}
                        breakpoints={{
                            "@0.00": {
                                slidesPerView: 2,
                                spaceBetween: 40
                            },
                            "@0.75": {
                                slidesPerView: 3,
                                spaceBetween: 40
                            },
                            "@1.00": {
                                slidesPerView: 4,
                                spaceBetween: 40
                            },
                            "@1.50": {
                                slidesPerView: 5,
                                spaceBetween: 40
                            }
                        }}
                    >
                        {carouselData.map((image, index) => (
                            <SwiperSlide
                                className="single-brand-logo"
                                key={index}
                            >
                                <img
                                    src={image.src}
                                    alt={image.alt}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </section>
    );
};

QualityAssurancCarousel.propTypes = {
  carouselData: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired
    })
  ).isRequired
};

export default QualityAssurancCarousel;