import React from 'react';
import TestimonialOne from '../Components/Testimonial/TestimonialOne';
import FooterTwo from '../Components/Footer/FooterTwo';
import HeaderThree from '../Components/Header/HeaderThree';
import bannerBg from '../assets/img/port-folio-1920x500.jpg';
import PageBanner from '../Components/PageBanner';
// import PortfolioOne from '../Components/Portfolio/PortfolioOne';
import PortfolioThree from '../Components/Portfolio/PortfolioThree';
import BrandCarouselFour from '../Components/Brands/BrandCarouselFour';
import Partners from "../Components/Services/Partners";
// import Details from '../Components/News/Details';

const ProjectPage = () => {
  return (
    <>
      <HeaderThree />
      <PageBanner
        title="Projects"
        bannerBg={bannerBg}
        currentPage="projects"
        bgColor="#f5f5f5"
        headingClass="text-white"
        breadcrumbColor="text-white"
        breadcrumbIconColor="#fff"
      />
      <TestimonialOne />
      <PortfolioThree />
      <Partners />
      <BrandCarouselFour />
      <FooterTwo />
    </>
  );
};

export default ProjectPage;