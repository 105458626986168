import React from 'react'
import FooterTwo from '../Components/Footer/FooterTwo'
import HeaderThree from '../Components/Header/HeaderThree'
// import bannerBg from '../assets/img/services-main.png';
import bannerBg from "../assets/img/ServicesOverview.png";
// import bannerBg from '../assets/img/serviceDetails-banner-1920X500.jpg';
import PageBanner from '../Components/PageBanner';
import Details from '../Components/Services/Details';

const ServiceDetails = () => {
  return (
    <>
        <HeaderThree/>
        <PageBanner title='Digital Transformation Partner' bannerBg={bannerBg} currentPage='services' />
        <Details/>
        <FooterTwo/>
    </>
  )
}

export default ServiceDetails;