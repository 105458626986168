import imgTestAutomations1 from '../../assets/img/services/qualityAssurance/testAutomation/cucumber.png';
import imgTestAutomations2 from '../../assets/img/services/qualityAssurance/testAutomation/cypress-io.png';
import imgTestAutomations3 from '../../assets/img/services/qualityAssurance/testAutomation/playwright.png';
import imgTestAutomations4 from '../../assets/img/services/qualityAssurance/testAutomation/postman.png';
import imgTestAutomations5 from '../../assets/img/services/qualityAssurance/testAutomation/puppeteer.png';
import imgTestAutomations6 from '../../assets/img/services/qualityAssurance/testAutomation/rest-assured.png';
import imgTestAutomations7 from '../../assets/img/services/qualityAssurance/testAutomation/selenium.png';
import imgTestAutomations8 from '../../assets/img/services/qualityAssurance/testAutomation/serenity.png';
import imgTestAutomations9 from '../../assets/img/services/qualityAssurance/testAutomation/test-ng.png';
import imgTestAutomations10 from '../../assets/img/services/qualityAssurance/testAutomation/webdriver-io.png';

import imgfunctionalAndUiTesting1 from '../../assets/img/services/lama.png';
import imgfunctionalAndUiTesting2 from '../../assets/img/services/lang.png';
import imgfunctionalAndUiTesting3 from '../../assets/img/services/qualityAssurance/functionaAndUITesting/zephyr-scale.png';
import imgfunctionalAndUiTesting4 from '../../assets/img/services/KFServing.jpg';
import imgfunctionalAndUiTesting5 from '../../assets/img/services/Kubeflow.jpg';
import imgfunctionalAndUiTesting6 from '../../assets/img/services/airbyte.png';

import imgProductionReady1 from '../../assets/img/services/qualityAssurance/productionReady/bandit.png';
import imgProductionReady2 from '../../assets/img/services/qualityAssurance/productionReady/burp-suite.png';
import imgProductionReady3 from '../../assets/img/services/qualityAssurance/productionReady/gremlin.png';
import imgProductionReady4 from '../../assets/img/services/qualityAssurance/productionReady/john-ripper.png';
import imgProductionReady5 from '../../assets/img/services/qualityAssurance/productionReady/nmap.png';
import imgProductionReady6 from '../../assets/img/services/qualityAssurance/productionReady/owasp.png';
import imgProductionReady7 from '../../assets/img/services/qualityAssurance/productionReady/sonarqube.png';

import imgBenchMarking1 from '../../assets/img/services/qualityAssurance/benchMarking/j-meter.png';
import imgBenchMarking2 from '../../assets/img/services/qualityAssurance/benchMarking/lighthouse.png';
import imgBenchMarking3 from '../../assets/img/services/qualityAssurance/benchMarking/locust.png';

const imagesFunctionalAndUiTesting = [
    { src: imgfunctionalAndUiTesting1, alt: 'lambda-test' },
    { src: imgfunctionalAndUiTesting2, alt: 'q-test' },
    { src: imgfunctionalAndUiTesting3, alt: 'zephyr-scale' },
    { src: imgfunctionalAndUiTesting4, alt: 'kf-scale' },
    { src: imgfunctionalAndUiTesting5, alt: 'kube-scale' },
    { src: imgfunctionalAndUiTesting6, alt: 'air-scale' }
];

const imagesTestAutomation = [
    { src: imgTestAutomations1, alt: 'cucumber' },
    { src: imgTestAutomations2, alt: 'cypress-io' },
    { src: imgTestAutomations3, alt: 'playwright' },
    { src: imgTestAutomations4, alt: 'postman' },
    { src: imgTestAutomations5, alt: 'puppeteer' },
    { src: imgTestAutomations6, alt: 'rest-assured' },
    { src: imgTestAutomations7, alt: 'selenium' },
    { src: imgTestAutomations8, alt: 'serenity' },
    { src: imgTestAutomations9, alt: 'test-ng' },
    { src: imgTestAutomations10, alt: 'webdriver-io' }
];

const imagesProductionReady = [
    { src: imgProductionReady1, alt: 'bandit' },
    { src: imgProductionReady2, alt: 'burp-suite' },
    { src: imgProductionReady3, alt: 'gremlin' },
    { src: imgProductionReady4, alt: 'john-ripper' },
    { src: imgProductionReady5, alt: 'nmap' },
    { src: imgProductionReady6, alt: 'owasp' },
    { src: imgProductionReady7, alt: 'sonarqube' }
];

const imagesBenchMarking = [
    { src: imgBenchMarking1, alt: 'j-meter' },
    { src: imgBenchMarking2, alt: 'lighthouse' },
    { src: imgBenchMarking3, alt: 'locust' }
];

export {
    imagesFunctionalAndUiTesting,
    imagesTestAutomation,
    imagesProductionReady,
    imagesBenchMarking
};