import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import DesignUXCarouselData from "./DesignUXCarouselData";

const WebServiceCarousel = () => {
  const firstHalfData = DesignUXCarouselData.slice(0, Math.ceil(DesignUXCarouselData.length / 2));
  const secondHalfData = DesignUXCarouselData.slice(Math.ceil(DesignUXCarouselData.length / 2));

  return (
    <section className="our-brand-showcase section-padding pt-0">
      <div className="container text-center">
        <h5 style={{ marginTop: "30px" }}>Toolset</h5>
        <div className="brand-carousel-2 mt-5">
          <Swiper
            slidesPerView={5}
            spaceBetween={20}
            loop
            autoplay={{
              delay: 5000,
              disableOnInteraction: false
            }}
            breakpoints={{
              "@0.00": {
                slidesPerView: 2,
                spaceBetween: 40
              },
              "@0.75": {
                slidesPerView: 3,
                spaceBetween: 40
              },
              "@1.00": {
                slidesPerView: 4,
                spaceBetween: 40
              },
              "@1.50": {
                slidesPerView: 5,
                spaceBetween: 40
              }
            }}
          >
            {firstHalfData.map((data) => (
              <SwiperSlide className="single-brand-logo" key={data.id}>
                <img src={data.brand} alt={data.name} />
                {/* <p>{data.name}</p> */}
              </SwiperSlide>
            ))}
          </Swiper>
          <Swiper
            slidesPerView={5}
            spaceBetween={20}
            loop
            autoplay={{
              delay: 4000,
              disableOnInteraction: false
            }}
            breakpoints={{
              "@0.00": {
                slidesPerView: 2,
                spaceBetween: 40
              },
              "@0.75": {
                slidesPerView: 3,
                spaceBetween: 40
              },
              "@1.00": {
                slidesPerView: 4,
                spaceBetween: 40
              },
              "@1.50": {
                slidesPerView: 5,
                spaceBetween: 40
              }
            }}
          >
            {secondHalfData.map((data) => (
              <SwiperSlide className="single-brand-logo" key={data.id}>
                <img src={data.brand} alt={data.name} />
                {/* <p>{data.name}</p> */}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  )
}

export default WebServiceCarousel
