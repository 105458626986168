import React from 'react'
import FooterTwo from '../Components/Footer/FooterTwo'
import HeaderThree from '../Components/Header/HeaderThree'
import bannerBg from '../assets/img/ContactUsHeader.png';
import PageBanner from '../Components/PageBanner';
// import BrandCarouselThree from "../Components/Brands/BrandCarouselThree";
import ContactPageContents from "../Components/Contact/ContactPageContents";

import { Element } from 'react-scroll';
// import BrandCarouselFour from '../Components/Brands/BrandCarouselFour';

const ContactPage = () => {
  return (
    <>
        <HeaderThree/>
        <PageBanner title='Contact Us' bannerBg={bannerBg} currentPage='contact' />
        <Element name="contact">
            <ContactPageContents/>
        </Element>
        {/* <BrandCarouselThree/> */}
        {/* <BrandCarouselFour /> */}
        <FooterTwo/>
    </>
  )
}

export default ContactPage;
