import { v4 as uuidv4 } from 'uuid';

import bg1 from '../../assets/img/designUXBg/dashboards-compressed.jpg';
import bg2 from '../../assets/img/designUXBg/custom-visual-compressed.jpg';
import bg3 from '../../assets/img/designUXBg/input-intensive-compressed.jpg';
import bg4 from '../../assets/img/designUXBg/videos-compressed.jpg';

const servicesOneData = [
  {
    id: uuidv4(),
    bg: bg1,
    icon: 'dashboards',
    title: 'Dashboards',
    desc: 'At eCav, we help you identify trends in your data through dashboards and derive actionable insights to support your business decisions. Our dashboards are designed to help you stay on top of your data and make informed decisions.'
  },
  {
    id: uuidv4(),
    bg: bg2,
    icon: 'custom-visual',
    title: 'Custom Visualizations',
    desc: "eCav's custom-built visuals are designed to best represent your data and help you identify hidden trends and analyze information. Our experts work closely with you to create the right visuals that meet your specific needs and give you an edge in your industry."
  },
  {
    id: uuidv4(),
    bg: bg3,
    icon: 'input-intensive',
    title: 'Input-Intensive Applications',
    desc: 'At eCav, we design interactive, context-based forms that engage users and encourage them to provide user input. Our input-intensive applications are designed to make user input easy and intuitive, helping you to gather the data you need to make informed decisions.'
  },
  {
    id: uuidv4(),
    bg: bg4,
    icon: 'videos',
    title: 'Videos',
    desc: "eCav's videos are designed to promote your products or create walkthroughs of their functionality that communicate the right message to the right target audience. Our videos are carefully crafted to showcase the features and benefits of your products and help you reach your target audience effectively."
  }
];

export default servicesOneData;