// import { Link } from "react-router-dom";
// import coreImg1 from "../../assets/img/home3/dataeng1.png";
// import coreImg2 from "../../assets/img/home3/aiblock3.jpg";
// import coreImg3 from "../../assets/img/home3/things.jpg";

const CoreFeaturesOne = () => {
    return (
        <section className="content-block-wrapper new-secton-padding bg-circle fix">
            <div className="container">
                <div className="row align-items-center">
                    <div className="core-feature-header">
                    <h2>Core Features</h2>

                    </div>
                    {/* <div className="col-xl-6 col-lg-6 pe-xl-4 col-12">
                        <div className="content-block-img">
                            <img src={coreImg1} alt="" />
                        </div>
                    </div> */}
                    <div className="col-xl-6 ps-xl-5 col-lg-6 col-12 mt-5 mt-lg-0">
                        <div className="section-title-3">
                            <h2>Data Pipelines and Engineering</h2>
                            <p>
                                Our cutting-edge solutions handle extremely high
                                data input/output rates, ensuring seamless
                                performance and ultra-low latency for your
                                data-intensive applications.
                            </p>
                        </div>
                        <div className="checked-features-list style-2">
                            <ul>
                                <li>Data Ingestion &amp; Processing</li>
                                <li>Data Storage &amp; Management</li>
                                <li>Data Transformation &amp; Integration</li>
                                <li>Real-time Streaming &amp; Analytics</li>
                                <li>
                                    Scalability &amp; Performance Optimization
                                </li>
                            </ul>
                        </div>
                        {/* <Link to="/services/CyberSecurity" className="theme-btn style-3 mt-30">
                            click here
                        </Link> */}
                    </div>
                    <div className="col-xl-6 ps-xl-5 col-lg-6 col-12 mt-5 mt-lg-0">
                        <div className="section-title-3">
                            <h2>AI Implementation and Predictive Analysis</h2>
                            <p>
                                We leverage cutting-edge AI technology to
                                deliver accurate predictive insights, helping
                                businesses optimize processes, enhance
                                decision-making, and drive innovation.
                            </p>
                        </div>
                        <div className="checked-features-list style-2">
                            <ul>
                                <li>
                                    Machine Learning Models &amp; Algorithms
                                </li>
                                <li>
                                    Data Preprocessing &amp; Feature Engineering
                                </li>
                                <li>Model Training &amp; Validation</li>
                                <li>
                                    Hyperparameter Tuning &amp; Optimization
                                </li>
                                <li>Model Deployment &amp; Monitoring</li>
                            </ul>
                        </div>
                        {/* <Link to="/services/AIAndAnalytics" className="theme-btn style-3 mt-30">
                            click here
                        </Link> */}
                    </div>
                </div>
                {/* <div className="row align-items-center section-padding pb-0">
                    <div className="col-xl-6 col-lg-6 col-12 mt-5 mt-lg-0 order-2 order-lg-1">
                        <div className="section-title-3">
                            <h2>AI Implementation and Predictive Analysis</h2>
                            <p>
                                We leverage cutting-edge AI technology to
                                deliver accurate predictive insights, helping
                                businesses optimize processes, enhance
                                decision-making, and drive innovation.
                            </p>
                        </div>
                        <div className="checked-features-list style-2">
                            <ul>
                                <li>
                                    Machine Learning Models &amp; Algorithms
                                </li>
                                <li>
                                    Data Preprocessing &amp; Feature Engineering
                                </li>
                                <li>Model Training &amp; Validation</li>
                                <li>
                                    Hyperparameter Tuning &amp; Optimization
                                </li>
                                <li>Model Deployment &amp; Monitoring</li>
                            </ul>
                        </div>
                        <Link to="/services/AIAndAnalytics" className="theme-btn style-3 mt-30">
                            click here
                        </Link>
                    </div>
                    <div className="col-xl-6 col-lg-6 ps-xl-5 col-12 order-1 order-lg-2">
                        <div className="content-block-img">
                            <img src={coreImg2} alt="" />
                        </div>
                    </div>
                </div> */}
                <div className="row mt-5">
                    {/* <div className="col-xl-6 col-lg-6 pe-xl-4 col-12">
                        <div className="content-block-img">
                            <img src={coreImg3} alt="" />
                        </div>
                    </div> */}
                    <div className="col-xl-6 ps-xl-5 col-lg-6 col-12 mt-5 mt-lg-0 ">
                        <div className="section-title-2">
                            <h2>Our Unique value proposition</h2>
                            <p>
                            We focus on AI, ML, Deep Learning, and NLP with a fully
                            developed AI-driven testing system, an internally developed
                            continuous integration, and continuous deployment methodology.
                            </p>
                        </div>
                        <div className="checked-features-list style-2">
                            <ul>
                                <li>Our Development Team selected by Microsoft to build 1Click Data & Analytics Solutions</li>
                                <li>Partnership with Microsoft to widely use MS Application</li>
                                <li>Selected by Amazon Web Services as a Partner for Cloud & DevOps Services</li>
                                <li>Server-less Architecture Deployment</li>
                                <li>Fully Developed AI-Driven Testing System</li>
                                <li>Internally Developed Continuous Integration & Continuous Deployment Methodology</li>
                            </ul>
                        </div>
                        {/* <Link to="/services/AIAndAnalytics" className="theme-btn style-3 mt-30">
                            click here
                        </Link> */}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CoreFeaturesOne;