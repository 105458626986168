import { v4 as uuidv4 } from 'uuid';

const categoriesData = [
    {
        id: uuidv4(),
        name: 'Consultant',
        link: '#',
        number: '23'
    },

    {
        id: uuidv4(),
        name: 'Help',
        link: '#',
        number: '25'
    },

    {
        id: uuidv4(),
        name: 'Education',
        link: '#',
        number: '14'
    },

    {
        id: uuidv4(),
        name: 'Technology',
        link: '#',
        number: '05'
    },

    {
        id: uuidv4(),
        name: 'Business',
        link: '#',
        number: '06'
    },

    {
        id: uuidv4(),
        name: 'Events',
        link: '#',
        number: '10'
    }

]

export default categoriesData