import React from 'react'
import FooterTwo from '../Components/Footer/FooterTwo'
import HeaderThree from '../Components/Header/HeaderThree'
import bannerBg from '../assets/img/SoftwareDevelopmentHeader.png';
import PageBanner from '../Components/PageBanner';
import WebServicesDetails from '../Components/Services/WebServicesDetails';
import WebServiceCarousel from "../Components/Brands/WebServiceCarousel";
// import WebDevProjectsTab from "../Components/Projects/WebDevProjectsTab";

const ServiceDetails = () => {
  return (
    <>
        <HeaderThree/>
        <PageBanner title='Software Development' bannerBg={bannerBg} currentPage='/services/SoftwareDevelopment' bgColor='#f5f5f5' />
        <WebServicesDetails/>
        <WebServiceCarousel />
        {/* <WebDevProjectsTab /> */}
        <FooterTwo/>
    </>
  )
}

export default ServiceDetails;