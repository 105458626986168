import { Link } from "react-router-dom";
import servicesOneData from "./webServicesData";
import "swiper/swiper-bundle.css";

import { ReactComponent as Icon1 } from "../../assets/img/icons/web-dev-icons/creative-idea.svg";
import { ReactComponent as Icon2 } from "../../assets/img/icons/web-dev-icons/cubes-svgrepo-com.svg";
import { ReactComponent as Icon3 } from "../../assets/img/icons/web-dev-icons/seo.svg";
import { ReactComponent as Icon4 } from "../../assets/img/icons/web-dev-icons/charts-visual.svg";
import { ReactComponent as Icon5 } from "../../assets/img/icons/web-dev-icons/forms-wizards.svg";
import { ReactComponent as Icon6 } from "../../assets/img/icons/web-dev-icons/web-optimize.svg";
import { ReactComponent as Icon7 } from "../../assets/img/icons/web-dev-icons/responsive-layout.svg";
import { ReactComponent as Icon8 } from "../../assets/img/icons/web-dev-icons/microservices-arch.svg";

const ServicesOne = () => {
  const getIconComponent = (iconName, width = 75, height = 75) => {
    switch (iconName) {
      case "creative-idea":
        return <Icon1 width={width} height={height} />;
      case "cubes-svgrepo-com":
        return <Icon2 width={width} height={height} />;
      case "seo":
        return <Icon3 width={width} height={height} />;
      case "charts-visual":
        return <Icon4 width={width} height={height} />;
      case "forms-wizards":
        return <Icon5 width={width} height={height} />;
      case "web-optimize":
        return <Icon6 width={width} height={height} />;
      case "responsive-layout":
        return <Icon7 width={width} height={height} />;
      case "microservices-arch":
        return <Icon8 width={width} height={height} />;
      default:
        return null;
    }
  };

  return (
    <section className="our-service-wrapper section-padding bg-gray">
      <div className="container">
        <div className="row mtm-30">
          <div className="col-md-6 col-12 col-lg-4 mt-30">
            <div className="block-contents">
              <div className="section-title">
                <span>Software Development</span>
                <h3>Building Scalable and Interactive Web Applications</h3>
              </div>
              <p>
                As a professional web development company, we specialize in
                building highly scalable and interactive web applications that
                deliver a simplified user experience and solve real-world
                problems.
              </p>
            </div>
          </div>
          {servicesOneData.map((data) => (
            <div className="col-md-6 col-12 col-lg-4 mt-30" key={data.id}>
              <div className="single-service-box style-1">
                <div
                  className="service-bg bg-cover"
                  style={{
                    backgroundImage: `url(${data.bg})`
                  }}
                ></div>
                <div className="icon">{getIconComponent(data.icon)}</div>
                <div
                  className="contents d-flex align-items-center justify-content-center"
                  style={{ height: "200px" }}
                >
                  <h4>
                    <Link to="/servicesDetails">{data.title}</Link>
                  </h4>
                  <p>{data.desc}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesOne;