import React from 'react'
import FooterTwo from '../Components/Footer/FooterTwo'
import HeaderThree from '../Components/Header/HeaderThree'
import bannerBg from '../assets/img/softwaretesting-ai.png';
import PageBanner from '../Components/PageBanner';
import QualityAssuranceDetails from '../Components/Services/QualityAssuranceDetails';

const ServiceDetails = () => {
  return (
    <>
        <HeaderThree/>
        <PageBanner title='Quality Assurance through Advanced Tech and Expertise.' bannerBg={bannerBg} currentPage='/services/QualityAssurance' bgColor='#f5f5f5' />
        <QualityAssuranceDetails/>
        <FooterTwo/>
    </>
  )
}

export default ServiceDetails;