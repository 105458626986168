import bannerBg from "../../assets/img/65.jpg";

const HeroFive = () => {
  return (
    <div className="">
      <section className="cta-promo-wrapper bg-cover main_page_img" style={{ backgroundImage: `url(${bannerBg})` }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8 text-center text-lg-start col-12 text-white">
              {/* <div className="home-heading">
                <h2 className="mb-0">eCav Technologies is a Service-Disabled Veteran Owned Small Business that provides IT services and solutions to public and private sectors. We specialize in working with the Federal and State governments within prime and subcontracting domains. </h2>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HeroFive;
